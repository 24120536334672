import React, { useState } from "react";
import { Form, Input, Button, Card, List, Modal, message } from "antd";
import { FileTextOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";

function DefectSolution() {
    const [form] = Form.useForm();
    const [solutions, setSolutions] = useState([
        {
            id: 1,
            rootCause: "login fail",
            solutionDescription: "abcxyz",
            stepsToFix: "1. a\n2. b\n3. c",
            createdAt: "2024-12-21 14:30",
            createdBy: "PhongVD"
        }
    ]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSolution, setSelectedSolution] = useState(null);

    const onFinish = (values) => {
        const newSolution = {
            id: solutions.length + 1,
            ...values,
            createdAt: new Date().toLocaleString(),
            createdBy: "Current User"
        };
        setSolutions([...solutions, newSolution]);
        message.success('Solution added successfully');
        form.resetFields();
    };

    const showSolutionDetail = (solution) => {
        setSelectedSolution(solution);
        setIsModalVisible(true);
    };

    const handleDeleteSolution = (solutionId) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this solution?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                setSolutions(solutions.filter(s => s.id !== solutionId));
                message.success('Solution deleted successfully');
            }
        });
    };

    return (
        <div className="solution-container">
{/*LIST SOLUTION*/}
            <List
                className="solutions-list"
                itemLayout="vertical"
                dataSource={solutions}
                renderItem={solution => (
                    <List.Item
                        key={solution.id}
                        actions={[
                            <Button
                                icon={<EyeOutlined />}
                                onClick={() => showSolutionDetail(solution)}
                            >
                                View
                            </Button>,
                            <Button
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() => handleDeleteSolution(solution.id)}
                            >
                                Delete
                            </Button>
                        ]}
                    >
                        <List.Item.Meta
                            title={<a onClick={() => showSolutionDetail(solution)}>Solution #{solution.id}</a>}
                            description={
                                <div>
                                    <p><strong>Created by:</strong> {solution.createdBy}</p>
                                    <p><strong>Created at:</strong> {solution.createdAt}</p>
                                </div>
                            }
                        />
                        <div className="solution-content">
                            <p><strong>Root Cause:</strong> {solution.rootCause.substring(0, 200)}...</p>
                        </div>
                    </List.Item>
                )}
            />

{/*CARD ADD SOLUTION            */}
            <Card className="solution-card">
                <div className="solution-header">
                    <FileTextOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
                    <h2 style={{ margin: 0 }}>Add Solution</h2>
                </div>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    className="solution-form"
                >
                    <Form.Item
                        label={'Root Cause Analysis'}
                        name="rootCause"
                        rules={[{ required: true, message: 'Please enter root cause analysis' }]}
                    >
                        <Input.TextArea
                            placeholder="Enter root cause analysis..."
                            autoSize={{ minRows: 4, maxRows: 6 }}
                        />
                    </Form.Item>

                    <Form.Item
                        label={'Solution Description'}
                        name="solutionDescription"
                        rules={[{ required: true, message: 'Please enter solution description' }]}
                    >
                        <Input.TextArea
                            placeholder="Enter solution description..."
                            autoSize={{ minRows: 4, maxRows: 6 }}
                        />
                    </Form.Item>

                    <Form.Item
                        label={'Steps to Fix'}
                        name="stepsToFix"
                        rules={[{ required: true, message: 'Please enter steps to fix' }]}
                    >
                        <Input.TextArea
                            placeholder="Enter steps to fix..."
                            autoSize={{ minRows: 4, maxRows: 6 }}
                        />
                    </Form.Item>

                    <Form.Item className="solution-actions">
                        <Button type="primary" htmlType="submit" className={'custom-ok-button'}>Submit</Button>
                    </Form.Item>
                </Form>
            </Card>

{/*SOLUTION DETAIL POP-UP*/}
            <Modal
                title={`Solution #${selectedSolution?.id} Details`}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                width={800}
            >
                {selectedSolution && (
                    <div className="solution-detail">
                        <div className="detail-section">
                            <h3>Root Cause Analysis</h3>
                            <p>{selectedSolution.rootCause}</p>
                        </div>
                        <div className="detail-section">
                            <h3>Solution Description</h3>
                            <p>{selectedSolution.solutionDescription}</p>
                        </div>
                        <div className="detail-section">
                            <h3>Steps to Fix</h3>
                            <p style={{ whiteSpace: 'pre-line' }}>{selectedSolution.stepsToFix}</p>
                        </div>
                        <div className="detail-meta">
                            <p><strong>Created by:</strong> {selectedSolution.createdBy}</p>
                            <p><strong>Created at:</strong> {selectedSolution.createdAt}</p>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
}

export default DefectSolution;