import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { useAuth } from "./AuthConext";
import { jwtDecode } from "jwt-decode";

const RootProtectedRoute = ({ element: Component, ...rest }) => {
    const { isLoggedIn } = useAuth();
    const token = Cookies.get('token');

    // Check if user is authenticated
    if (!token || !isLoggedIn) {
        return <Navigate to="/login" />;
    }

    // Check if user is root
    try {
        const decoded = jwtDecode(token);
        if (decoded.sub !== 'root') {
            // If not root, redirect to home page or show unauthorized page
            return <Navigate to="/" />;
        }
    } catch (error) {
        console.error('Error decoding token:', error);
        return <Navigate to="/login" />;
    }

    // If authenticated and root, render the component
    return <Component {...rest} />;
};

export default RootProtectedRoute;