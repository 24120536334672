import react from 'react';
import {FileOutlined, FolderOutlined} from "@ant-design/icons";
import {Table} from "antd";


const FileTable = ({ list, loading, onNameClick }) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name_file',
            render: (text, record) => (
                <div
                    className="file-name-cell"
                    onClick={() => onNameClick(record)}
                    style={{ cursor: 'pointer', color: '#1890ff' }}
                >
                    {record.type === 'directory' ? (
                        <FolderOutlined style={{ marginRight: 8, color: '#ffd591' }} />
                    ) : (
                        <FileOutlined style={{ marginRight: 8, color: '#8c8c8c' }} />
                    )}
                    {text}
                </div>
            )
        },
        {
            title: 'Last commit',
            dataIndex: 'lastCommit',
            key: 'last_commit'
        },
        {
            title: 'Last update',
            dataIndex: 'lastUpdate',
            key: 'last_update'
        }
    ];

    return (
        <div className="list-file-repository">
            <style jsx>{`
        .file-name-cell:hover {
          text-decoration: underline;
        }
      `}</style>
            <Table columns={columns} dataSource={list} loading={loading} />
        </div>
    );
};

export default FileTable;