import React, { useState, useEffect } from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import { Avatar, Card, Col, Row, Typography, message, Spin, Tag } from "antd";
import { UserOutlined, HomeOutlined, LoginOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import axios from 'axios';
import api from "../Ultils/Api";

function UserDetail() {
    const [loading, setLoading] = useState(true);
    const [userDetail, setUserDetail] = useState(null);
    const { nameDetail } = useParams();
    const { Title, Text } = Typography;
    const {username} = useParams()

    const getRoleColor = (role) => {
        switch (role.toLowerCase()) {
            case 'owner':
                return 'gold';
            case 'dev':
                return 'blue';
            default:
                return 'default';
        }
    };

    useEffect(() => {
        fetchUserDetail();
    }, [username]);

    const fetchUserDetail = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/root/user/${username}`, {
                headers: {
                    'Authorization': token
                }
            });

            setUserDetail(response.data.user);
        } catch (error) {
            message.error('Failed to fetch user details: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <LayoutComponentDetail>
                <Card className="account-detail-card">
                    <div style={{ textAlign: 'center', padding: '50px' }}>
                        <Spin size="large" />
                    </div>
                </Card>
            </LayoutComponentDetail>
        );
    }

    if (!userDetail) {
        return (
            <LayoutComponentDetail>
                <Card className="account-detail-card">
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <Text type="secondary">No user details found</Text>
                    </div>
                </Card>
            </LayoutComponentDetail>
        );
    }

    return (
        <LayoutComponentDetail>
            <Card className="account-detail-card">
                <Title level={2} className="account-detail-title">Information Details</Title>

                <Row align="middle" className="account-info-container">
                    <Col span={2}>
                        <Avatar
                            size={100}
                            icon={<UserOutlined />}
                            className="account-avatar"
                        />
                    </Col>
                    <Col span={20}>
                        <Title level={4} className="account-name">{userDetail.real_name || userDetail.username}</Title>
                        <Text type="secondary">@{userDetail.username}</Text>
                    </Col>
                </Row>

                <div className="account-detail-content">
                    <div className="info-section">
                        <Text strong className="info-label">
                            User ID:
                            <Text> {userDetail.uid}</Text>
                        </Text>
                    </div>
                    <div className="info-section">
                        <Text strong className="info-label">
                            Email:
                            <Text> {userDetail.email || '-'}</Text>
                        </Text>
                    </div>
                    <div className="info-section">
                        <Text strong className="info-label">
                            <HomeOutlined /> Home Directory:
                            <Text> {userDetail.home}</Text>
                        </Text>
                    </div>
                    <div className="info-section">
                        <Text strong className="info-label">
                            Account Status:
                            <Tag
                                color={userDetail.account_status === 'active' ? 'green' : 'red'}
                                style={{ marginLeft: '8px' }}
                            >
                                {userDetail.account_status}
                            </Tag>
                        </Text>
                    </div>
                    <div className="info-section">
                        <Text strong className="info-label">
                            Projects:
                        </Text>
                        <div className="projects-container" style={{ marginTop: '8px' }}>
                            {userDetail.projects.length > 0 ? (
                                userDetail.projects
                                    .filter(project => project.repository) // Filter out empty repository names
                                    .map((project, index) => (
                                        <div key={index} className="project-item" style={{ marginBottom: '8px' }}>
                                            {project.repository}
                                            <Tag
                                                color={getRoleColor(project.role)}
                                                style={{ marginLeft: '8px' }}
                                            >
                                                {project.role}
                                            </Tag>
                                        </div>
                                    ))
                            ) : (
                                <Text type="secondary">No projects assigned</Text>
                            )}
                        </div>
                    </div>
                </div>
            </Card>

            <style jsx>{`
                .account-detail-card {
                    margin: 24px;
                    border-radius: 8px;
                }
                .account-detail-title {
                    margin-bottom: 24px !important;
                }
                .account-info-container {
                    margin-bottom: 32px;
                }
                .account-avatar {
                    background-color: #f0f2f5;
                }
                .info-section {
                    margin-bottom: 16px;
                }
                .project-item {
                    padding: 8px 16px;
                    background-color: #f0f2f5;
                    border-radius: 4px;
                    display: inline-flex;
                    align-items: center;
                    margin-right: 8px;
                }
            `}</style>
        </LayoutComponentDetail>
    );
}

export default UserDetail;