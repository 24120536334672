import React, {useState} from "react";
import {Button, Card, Input, Radio, Typography} from "antd";
function EditProfile({userInfo,onSave,onCancel}) {
    const {Text} = Typography;
    const [editedInfor,setEditedInfor] = useState(userInfo);
    const handleInputChange = (field, value) => {
        setEditedInfor(prev => ({
            ...prev,
            [field]: value
        }));
    };
    const handleSave = () => {
        onSave(editedInfor)
    };
    return (
        <>
            <Card title={'Edit Information'} className={'profile-information-card ant-card'}>
                <div className={'profile-information-content'}>
                    <div className={'profile-information-grid'}>
                        <div className={'profile-information-item'}>
                            <Text className={'profile-information-label'}>Name:</Text>
                            <Input
                                    className={'profile-information-input'}
                                    value={editedInfor.name}
                                    onChange={(e)=> handleInputChange('name',e.target.value)}
                            />
                        </div>
                        <div className={'profile-information-item'}>
                            <Text className={'profile-information-label'}>Gender:</Text>
                            <Radio.Group
                                value={editedInfor.gender}
                                onChange={(e)=>handleInputChange('gender',e.target.value)}
                                className={'profile-information-input'}
                            >
                                <Radio value={'Male'}>Male</Radio>
                                <Radio value={'Female'}>Female</Radio>
                                <Radio value={'Other'}>Other</Radio>

                            </Radio.Group>
                        </div>
                    </div>
                    <div className={'profile-information-item'}>
                        <Text className={'profile-information-label'}>Email:</Text>
                        <Input
                                className={'profile-information-input'}
                                value={editedInfor.email}
                                onChange={(e)=>handleInputChange('email',e.target.value)}
                        />
                    </div>
                    <div className={'profile-information-item'}>
                        <Text className={'profile-information-label'}>Date of birth:</Text>
                        <Input
                            type={'date'}
                            className={'profile-information-input'}
                            value={editedInfor.dateOfBirth}
                            onChange={(e)=>handleInputChange('dateOfBirth',e.target.value)}
                        />
                    </div>
                    <div className={'profile-information-item'}>
                        <Text className={'profile-information-label'}>Country:</Text>
                        <Input
                                className={'profile-information-input'}
                                value={editedInfor.country}
                                onChange={(e)=>handleInputChange('country',e.target.value)}
                        />
                    </div>
                    <div className={'profile-information-item'}>
                        <Text className={'profile-information-label'}>Phone number:</Text>
                        <Input
                                className={'profile-information-input'}
                                value={editedInfor.phoneNumber}
                                onChange={(e)=>handleInputChange('phoneNumber',e.target.value)}
                        />
                    </div>
                    <div className={'profile-button-container'}>
                        <Button
                            className={'profile-button'}
                            onClick={handleSave}
                            type={'primary'}
                        >
                            Save
                        </Button>
                        <Button
                            className={'profile-button-cancel'}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Card>
        </>
    )
}
export default EditProfile;