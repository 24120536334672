import React, {useState} from "react";
import {Button, Layout} from "antd";
import Sider from "antd/es/layout/Sider";
import SideBar from "./SideBar";
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import HeaderComponent from "./HeaderComponent";
import '../Assets/css/index.css'


function LayoutComponent({children}) {
    const [collapsed,setCollapsed] = useState(false);
    return(
        <>
            <Layout className={'layout-container'}>
                <Sider theme={'light'} trigger={null} collapsible collapsed={collapsed} className='sider'>
                    <SideBar/>
                    <Button type={'text'}
                            icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                            onClick={()=>setCollapsed(!collapsed)}
                            className={'trigger-btn'}
                    />
                </Sider>
                <Layout>
                        <HeaderComponent/>
                             <div className={'content'}>
                                 {children}
                             </div>
                </Layout>
            </Layout>
        </>
    )
}
export default LayoutComponent