import React, { useState, useEffect } from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, DatePicker, Form, message, Modal, Row, Select, Table, Tag } from "antd";
import { DeleteOutlined, SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import Input from "antd/es/input/Input";
import dayjs from "dayjs";
import api from '../Ultils/Api';

const { Option } = Select;

function Defects() {
    // States for managing defects
    const [defects, setDefects] = useState([]);
    const [loading, setLoading] = useState(false);

    // State search,sort,filter
    const [searchText, setSearchText] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortField, setSortField] = useState('defect_creation_time');

    // States for managing members
    const [membersList, setMembersList] = useState([]);
    const [membersLoading, setMembersLoading] = useState(false);

    // States for managing branches
    const [branches, setBranches] = useState([]);
    const [branchesLoading, setBranchesLoading] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState('');

    // Modal states
    const [isModalVisibleCreateDefect, setIsModalVisibleCreateDefect] = useState(false);
    const [isModalVisibleDeleteDefect, setIsModalVisibleDeleteDefect] = useState(false);
    const [defectToDelete, setDefectToDelete] = useState(null);

    // Form states
    const [newTitle, setNewTitle] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [assignPerson, setAssignPerson] = useState('');
    const [priority, setPriority] = useState('');
    const [severity, setSeverity] = useState('');
    const [deadline, setDeadline] = useState(null);

    // Hooks
    const { repositoryName } = useParams();
    const navigate = useNavigate();

    // Color utility functions
    const getStatusColor = (status) => {
        switch (status) {
            case 'NEW': return 'blue';
            case 'IN_PROGRESS': return 'orange';
            case 'RESOLVED': return 'green';
            case 'CLOSED': return 'gray';
            default: return 'default';
        }
    };

    const getPriorityColor = (priority) => {
        switch (priority) {
            case 'HIGH': return 'red';
            case 'MEDIUM': return 'orange';
            case 'LOW': return 'green';
            default: return 'default';
        }
    };

    const getSeverityColor = (severity) => {
        switch (severity) {
            case 'CRITICAL': return 'red';
            case 'MAJOR': return 'orange';
            case 'MINOR': return 'green';
            default: return 'default';
        }
    };

    // Table columns configuration
    const columns = [
        {
            title: 'Title',
            dataIndex: 'defect_title',
            key: 'defect_title',
            sorter: (a, b) => a.defect_title.localeCompare(b.defect_title),
            render: (text, record) => (
                <a onClick={() => handleDefectClick(record.key)}>{text}</a>
            )
        },
        {
            title: 'Reporter',
            dataIndex: 'defect_reporter',
            key: 'defect_reporter',
            sorter: (a, b) => a.defect_reporter.localeCompare(b.defect_reporter)
        },
        {
            title: 'Creation Time',
            dataIndex: 'defect_creation_time',
            key: 'defect_creation_time',
            sorter: (a, b) => dayjs(a.defect_creation_time).unix() - dayjs(b.defect_creation_time).unix(),
            render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'Assigned Person',
            dataIndex: 'defect_assigned_person',
            key: 'defect_assigned_person'
        },
        {
            title: 'Deadline',
            dataIndex: 'defect_deadline',
            key: 'defect_deadline',
            render: (text) => text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-'
        },
        {
            title: 'Last Modified Time',
            dataIndex: 'defect_last_modified_time',
            key: 'defect_last_modified_time',
            render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'Priority',
            dataIndex: 'defect_priority',
            key: 'defect_priority',
            render: (priority) => (
                <Tag color={getPriorityColor(priority)}>{priority}</Tag>
            )
        },
        {
            title: 'Severity',
            dataIndex: 'defect_severity',
            key: 'defect_severity',
            render: (severity) => (
                <Tag color={getSeverityColor(severity)}>{severity}</Tag>
            )
        },
        {
            title: 'Status',
            dataIndex: 'defect_status',
            key: 'defect_status',
            render: (status) => (
                <Tag color={getStatusColor(status)}>{status}</Tag>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button danger onClick={() => handleShowDeleteDefectModal(record)}>
                    <DeleteOutlined />
                </Button>
            )
        }
    ];

    // Effect hooks
    useEffect(() => {
        if (repositoryName) {
            fetchDefects();
            fetchMembers();
            fetchBranches();
        }
    }, [repositoryName]);

    // API functions
    const fetchDefects = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/defects/repository/${repositoryName}`);
            const formattedDefects = response.data.map(defect => ({
                key: defect.id,
                defect_title: defect.title,
                defect_reporter: defect.createdBy,
                defect_creation_time: defect.createdAt,
                defect_assigned_person: defect.assignedTo,
                defect_deadline: defect.deadline,
                defect_last_modified_time: defect.updatedAt,
                defect_priority: defect.priority,
                defect_severity: defect.severity,
                defect_status: defect.status
            }));
            setDefects(formattedDefects);
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchMembers = async () => {
        setMembersLoading(true);
        try {
            const response = await api.get(`/member/${repositoryName}/members`);
            if (response.data) {
                const members = Object.values(response.data.members).map(member => ({
                    account: member.Account,
                    role: member.Role
                }));
                setMembersList(members);
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setMembersLoading(false);
        }
    };

    const fetchBranches = async () => {
        setBranchesLoading(true);
        try {
            const response = await api.get(`/git/repositories/${repositoryName}/branches`);
            const branchData = response.data;
            if (branchData && branchData[repositoryName]) {
                const branchList = branchData[repositoryName].map(branch => branch.name);
                setBranches(branchList);
                if (branchList.length > 0) {
                    setSelectedBranch(branchList[0]);
                }
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setBranchesLoading(false);
        }
    };

    // Event handlers
    const handleSearch = (value) => {
        setSearchText(value);
    };

    const handleSortChange = (value) => {
        setSortField(value);
    };

    const toggleSortOrder = () => {
        setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    };

    const handleDefectClick = (defectId) => {
        navigate(`/repository/${repositoryName}/defect/${defectId}`);
    };

    const handleShowDeleteDefectModal = (defect) => {
        setDefectToDelete(defect);
        setIsModalVisibleDeleteDefect(true);
    };

    const handleCreateDefect = async () => {
        if (newTitle.trim() === '') {
            message.error('Please enter a valid title');
            return;
        }

        if (!selectedBranch) {
            message.error('Please select a branch');
            return;
        }

        try {
            const formattedDeadline = deadline ?
                dayjs(deadline).format('YYYY-MM-DDTHH:mm:ss') :
                null;

            const payload = {
                title: newTitle,
                description: newDescription,
                assignedTo: assignPerson,
                deadline: formattedDeadline,
                priority: priority,
                severity: severity,
                branchName: selectedBranch
            };

            await api.post(`/defects/repository/${repositoryName}`, payload);
            message.success('Defect created successfully');
            setIsModalVisibleCreateDefect(false);
            clearForm();
            fetchDefects();
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleDeleteDefect = async () => {
        if (defectToDelete) {
            try {
                await api.delete(`/git/repositories/${repositoryName}/defects/${defectToDelete.key}`);
                message.success('Defect deleted successfully');
                setIsModalVisibleDeleteDefect(false);
                setDefectToDelete(null);
                fetchDefects();
            } catch (error) {
                handleApiError(error);
            }
        }
    };

    const handleApiError = (error) => {
        console.error('API Error:', error);
        if (error.response?.status === 401) {
            message.error('Session expired. Please login again.');
            navigate('/login');
        } else {
            message.error('Operation failed. Please try again.');
        }
    };

    const clearForm = () => {
        setNewTitle('');
        setNewDescription('');
        setAssignPerson('');
        setPriority('');
        setSeverity('');
        setDeadline(null);
        setSelectedBranch('');
    };

    const getFilteredAndSortedData = () => {
        return defects
            .filter(defect => {
                const searchLower = searchText.toLowerCase();
                return (
                    (defect.defect_title && defect.defect_title.toLowerCase().includes(searchLower)) ||
                    (defect.defect_reporter && defect.defect_reporter.toLowerCase().includes(searchLower)) ||
                    (defect.defect_assigned_person && defect.defect_assigned_person.toLowerCase().includes(searchLower)) ||
                    (defect.defect_status && defect.defect_status.toLowerCase().includes(searchLower))
                );
            })
            .sort((a, b) => {
                let comparison = 0;
                switch (sortField) {
                    case 'defect_title':
                    case 'defect_reporter':
                    case 'defect_assigned_person':
                    case 'defect_status':
                        comparison = (a[sortField] || '').localeCompare(b[sortField] || '');
                        break;
                    case 'defect_creation_time':
                    case 'defect_deadline':
                    case 'defect_last_modified_time':
                        const dateA = dayjs(a[sortField]);
                        const dateB = dayjs(b[sortField]);
                        comparison = dateA.diff(dateB);
                        break;
                    default:
                        comparison = 0;
                }
                return sortOrder === 'asc' ? comparison : -comparison;
            });
    };

    // Select options
    const renderPriorityOption = (value) => (
        <Option value={value}>
            <Tag color={getPriorityColor(value)}>{value}</Tag>
        </Option>
    );

    const renderSeverityOption = (value) => (
        <Option value={value}>
            <Tag color={getSeverityColor(value)}>{value}</Tag>
        </Option>
    );

    const sortOptions = [
        { value: 'defect_title', label: 'Title' },
        { value: 'defect_reporter', label: 'Reporter' },
        { value: 'defect_creation_time', label: 'Creation Time' },
        { value: 'defect_deadline', label: 'Deadline' },
        { value: 'defect_last_modified_time', label: 'Last Modified' },
        { value: 'defect_status', label: 'Status' }
    ];

    return (
        <LayoutComponentDetail>
            <div className="content-title">
                <h1>Defects of Repository {repositoryName}</h1>
            </div>

            <Row gutter={16} className="search-sort-container">
                <Col span={18}>
                    <Search
                        placeholder="Search"
                        className="search"
                        allowClear
                        onSearch={handleSearch}
                        style={{ borderRadius: '25px', height: '40px', marginLeft: '5px' }}
                    />
                </Col>
                <Col span={4}>
                    <Select
                        value={sortField}
                        className="select-sort"
                        style={{ width: '100%' }}
                        onChange={handleSortChange}
                    >
                        {sortOptions.map(option => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={2}>
                    <Button
                        type="text"
                        icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                        onClick={toggleSortOrder}
                        className="sort-btn"
                        style={{ width: '78%' }}
                    />
                </Col>
            </Row>

            <div className={'y'}>
                <Button
                    type="primary"
                    className="create-defect-button"
                    onClick={() => setIsModalVisibleCreateDefect(true)}
                >
                    Create Defect
                </Button>
            </div>

            <div className="defect-list">
                <Table
                    columns={columns}
                    dataSource={getFilteredAndSortedData()}
                    loading={loading}
                    pagination={{
                        pageSize: 10,
                        showTotal: (total) => `Total ${total} defects`
                    }}
                />

                {/* Delete Defect Modal */}
                <Modal
                    title="Delete Defect"
                    open={isModalVisibleDeleteDefect}
                    onOk={handleDeleteDefect}
                    onCancel={() => {
                        setIsModalVisibleDeleteDefect(false);
                        setDefectToDelete(null);
                    }}
                    okText="Delete"
                    cancelText="Cancel"
                    okButtonProps={{
                        className: 'custom-ok-button',
                        danger: true
                    }}
                >
                    {defectToDelete && (
                        <p>Are you sure you want to delete <strong>{defectToDelete.defect_title}</strong>?</p>
                    )}
                </Modal>

                {/* Create Defect Modal */}
                <Modal
                    title="Create Defect"
                    open={isModalVisibleCreateDefect}
                    onOk={handleCreateDefect}
                    onCancel={() => {
                        setIsModalVisibleCreateDefect(false);
                        clearForm();
                    }}
                    okText="Create"
                    cancelText="Cancel"
                    okButtonProps={{ className: 'custom-ok-button' }}
                    width={700}
                >
                    <Form layout="vertical">
                        <Form.Item
                            label="Title"
                            name="defectTitle"
                            rules={[{ required: true, message: "Please input the name of the defect" }]}
                            validateTrigger="onBlur"
                        >
                            <Input
                                type="text"
                                placeholder="Enter defect title"
                                value={newTitle}
                                onChange={(e) => setNewTitle(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="defectDescription"
                            rules={[{ required: true, message: "Please input the description of the defect" }]}
                            validateTrigger="onBlur"
                        >
                            <Input.TextArea
                                placeholder="Enter defect description"
                                value={newDescription}
                                onChange={(e) => setNewDescription(e.target.value)}
                                rows={4}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Branch"
                            name="branch"
                            rules={[{ required: true, message: "Please select a branch" }]}
                            validateTrigger="onBlur"
                        >
                            <Select
                                placeholder="Select Branch"
                                value={selectedBranch}
                                onChange={(value) => setSelectedBranch(value)}
                                loading={branchesLoading}
                            >
                                {branches.map((branch) => (
                                    <Option key={branch} value={branch}>
                                        {branch}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Priority"
                                    name="defectPriority"
                                    rules={[{ required: true, message: 'Please choose the priority' }]}
                                    validateTrigger="onBlur"
                                >
                                    <Select
                                        placeholder="Choose priority"
                                        value={priority}
                                        onChange={(value) => setPriority(value)}
                                    >
                                        {renderPriorityOption("HIGH")}
                                        {renderPriorityOption("MEDIUM")}
                                        {renderPriorityOption("LOW")}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Severity"
                                    name="defectSeverity"
                                    rules={[{ required: true, message: 'Please choose the severity' }]}
                                    validateTrigger="onBlur"
                                >
                                    <Select
                                        placeholder="Choose severity"
                                        value={severity}
                                        onChange={(value) => setSeverity(value)}
                                    >
                                        {renderSeverityOption("CRITICAL")}
                                        {renderSeverityOption("MAJOR")}
                                        {renderSeverityOption("MINOR")}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Assign person"
                                    name="defectAssignPerson"
                                    rules={[{ required: true, message: "Please choose the person to assign" }]}
                                    validateTrigger="onBlur"
                                >
                                    <Select
                                        placeholder="Select Person"
                                        value={assignPerson}
                                        onChange={(value) => setAssignPerson(value)}
                                        loading={membersLoading}
                                    >
                                        {membersList.map((member) => (
                                            <Option key={member.account} value={member.account}>
                                                {member.account} ({member.role})
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Deadline"
                                    name="defectDeadline"
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        value={deadline}
                                        onChange={(date) => setDeadline(date)}
                                        showTime
                                        format="YYYY-MM-DD HH:mm:ss"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        </LayoutComponentDetail>
    );
}

export default Defects;