import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

// Constants
const TOKEN_COOKIE_NAME = 'token';
const TOKEN_EXPIRY_THRESHOLD = 5 * 60; // 5 minutes in seconds before expiry to warn user

const AuthContext = createContext();

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(Cookies.get(TOKEN_COOKIE_NAME));
    const [tokenExpiryWarning, setTokenExpiryWarning] = useState(false);

    // Validate token and decode user information
    const validateToken = useCallback((token) => {
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            // Check if token has expired
            if (!decodedToken.exp || currentTime >= decodedToken.exp) {
                return { isValid: false, user: null };
            }

            // Check if token is close to expiry
            if (decodedToken.exp - currentTime < TOKEN_EXPIRY_THRESHOLD) {
                setTokenExpiryWarning(true);
            }

            // Extract user information from token
            const user = {
                id: decodedToken.sub,
                email: decodedToken.email,
                roles: decodedToken.roles || [],
                exp: decodedToken.exp
            };

            return { isValid: true, user };
        } catch (error) {
            console.error('Token validation error:', error.message);
            return { isValid: false, user: null };
        }
    }, []);

    // Initialize authentication state
    useEffect(() => {
        if (token) {
            const { isValid, user } = validateToken(token);
            if (!isValid) {
                handleLogout();
            } else {
                setUser(user);
                setIsLoggedIn(true);
            }
        }
        setLoading(false);
    }, [token, validateToken]);

    // Set up token expiry check
    useEffect(() => {
        if (!token || !isLoggedIn) return;

        const checkTokenExpiry = () => {
            const { isValid } = validateToken(token);
            if (!isValid) {
                handleLogout();
            }
        };

        // Check token validity every minute
        const interval = setInterval(checkTokenExpiry, 60000);
        return () => clearInterval(interval);
    }, [token, isLoggedIn, validateToken]);

    const handleLogin = useCallback((newToken) => {
        try {
            const { isValid, user } = validateToken(newToken);
            if (!isValid) {
                throw new Error('Invalid token provided');
            }

            Cookies.set(TOKEN_COOKIE_NAME, newToken, {
                secure: process.env.NODE_ENV === 'production',
                sameSite: 'strict'
            });

            setToken(newToken);
            setUser(user);
            setIsLoggedIn(true);
            setTokenExpiryWarning(false);
        } catch (error) {
            console.error('Login error:', error.message);
            throw error;
        }
    }, [validateToken]);

    const handleLogout = useCallback(() => {
        setIsLoggedIn(false);
        setUser(null);
        setToken(null);
        setTokenExpiryWarning(false);
        Cookies.remove(TOKEN_COOKIE_NAME);
    }, []);

    // Handle token expiry warning
    useEffect(() => {
        if (tokenExpiryWarning) {
            // You can implement your own warning mechanism here
            console.warn('Token will expire soon. Please re-login.');
        }
    }, [tokenExpiryWarning]);

    if (loading) {
        return (
            <div id="loading" className="flex items-center justify-center min-h-screen">
                <div className="loader simple-loader">
                    <div className="loader-body"></div>
                </div>
            </div>
        );
    }

    const contextValue = {
        isLoggedIn,
        user,
        token,
        tokenExpiryWarning,
        login: handleLogin,
        logout: handleLogout
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;