import React, { useState, useEffect } from 'react';
import { Flex, Menu } from "antd";
import logo from "../Assets/image/logo.jpg"
import {
    BugOutlined,
    FolderOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const SideBar = () => {
    const [isRootUser, setIsRootUser] = useState(false);

    useEffect(() => {
        const token = Cookies.get("token");
        if (token) {
            try {
                const decoded = jwtDecode(token);
                // Check if username is 'root'
                setIsRootUser(decoded.sub === 'root');
            } catch (error) {
                console.error('Error decoding token:', error);
                setIsRootUser(false);
            }
        }
    }, []);

    return (
        <>
            <Flex align="center" justify="center">
                <Link to="/">
                    <img className="logo" src={logo} alt="Logo" />
                </Link>
            </Flex>
            <Menu mode="inline" className="menu-bar">
                <Menu.Item key="1" icon={<FolderOutlined />}>
                    <Link to="/">Repository</Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<BugOutlined />}>
                    <Link to="/defectIndividual">Defect</Link>
                </Menu.Item>
                {isRootUser && (
                    <Menu.Item key="3" icon={<UserOutlined />}>
                        <Link to="/user">User</Link>
                    </Menu.Item>
                )}
            </Menu>
        </>
    );
};

export default SideBar;