import React, {useEffect, useState} from "react";
import {Button, Col, Layout, Row, Select, Table, message, Tag} from "antd";
import Sider from "antd/es/layout/Sider";
import SideBar from "../Layout/SideBar";
import {MenuFoldOutlined, MenuUnfoldOutlined, SortAscendingOutlined, SortDescendingOutlined} from "@ant-design/icons";
import HeaderComponent from "../Layout/HeaderComponent";
import Search from "antd/es/input/Search";
import api from "../Ultils/Api"
import moment from 'moment';
import {useNavigate, useParams} from "react-router-dom";

function DefectIndividual() {
    const {Option} = Select;
    const [collapsed, setCollapsed] = useState(false);
    const [typeSort, setTypeSort] = useState(false);
    const [defects, setDefects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [sortField, setSortField] = useState('name');
    const navigate = useNavigate();


    const getStatusColor = (status) => {
        switch (status) {
            case 'NEW':
                return 'blue';
            case 'IN_PROGRESS':
                return 'orange';
            case 'RESOLVED':
                return 'green';
            case 'CLOSED':
                return 'gray';
            default:
                return 'default';
        }
    };

    const getPriorityColor = (priority) => {
        switch (priority) {
            case 'HIGH':
                return 'red';
            case 'MEDIUM':
                return 'orange';
            case 'LOW':
                return 'green';
            default:
                return 'default';
        }
    };

    const getSeverityColor = (severity) => {
        switch (severity) {
            case 'CRITICAL':
                return 'red';
            case 'MAJOR':
                return 'orange';
            case 'MINOR':
                return 'green';
            default:
                return 'default';
        }
    };

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <a onClick={() => handleDefectClick(record.id, record.repositoryName)}>{text}</a>
            ),
            filteredValue: [searchText],
            onFilter: (value, record) => {
                return String(record.title).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.repositoryName).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.createdBy).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.assignedTo).toLowerCase().includes(value.toLowerCase());
            }
        },
        {
            title: 'Repository',
            dataIndex: 'repositoryName',
            key: 'repositoryName',
            render: (text, record) => (
                <span>{text} ({record.branchName})</span>
            )
        },
        {
            title: 'Reporter',
            dataIndex: 'createdBy',
            key: 'createdBy'
        },
        {
            title: 'Creation Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm')
        },
        {
            title: 'Assigned Person',
            dataIndex: 'assignedTo',
            key: 'assignedTo'
        },
        {
            title: 'Deadline',
            dataIndex: 'deadline',
            key: 'deadline',
            render: (text) => text ? moment(text).format('YYYY-MM-DD HH:mm') : '-'
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            render: (priority) => (
                <Tag color={getPriorityColor(priority)}>{priority}</Tag>
            )
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            render: (severity) => (
                <Tag color={getSeverityColor(severity)}>{severity}</Tag>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag color={getStatusColor(status)}>{status}</Tag>
            )
        }
    ];

    useEffect(() => {
        fetchDefects();
    }, []);

    const fetchDefects = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/defects/user-relate', {
                headers: {
                    'Authorization': token
                },
                params: {
                    targetHost: 'ADMIN'
                }
            });

            // Add key property for Table
            const transformedData = response.data.map(defect => ({
                ...defect,
                key: defect.id
            }));

            setDefects(transformedData);
        } catch (error) {
            message.error('Failed to fetch defects: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    const handleDefectClick = (defectId, repositoryName) => {
        navigate(`/repository/${repositoryName}/defect/${defectId}`);
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const handleSort = (value) => {
        setSortField(value);
        const sortedDefects = [...defects].sort((a, b) => {
            const direction = typeSort ? 1 : -1;
            switch(value) {
                case 'name':
                    return direction * a.title.localeCompare(b.title);
                case 'created':
                    return direction * (new Date(a.createdAt) - new Date(b.createdAt));
                case 'priority': {
                    const priorityOrder = {'HIGH': 3, 'MEDIUM': 2, 'LOW': 1};
                    return direction * (priorityOrder[a.priority] - priorityOrder[b.priority]);
                }
                case 'severity': {
                    const severityOrder = {'CRITICAL': 3, 'MAJOR': 2, 'MINOR': 1};
                    return direction * (severityOrder[a.severity] - severityOrder[b.severity]);
                }
                default:
                    return 0;
            }
        });
        setDefects(sortedDefects);
    };

    return (
        <Layout className={'layout-container'}>
            <Sider theme={'light'} trigger={null} collapsible collapsed={collapsed} className='sider'>
                <SideBar/>
                <Button
                    type={'text'}
                    icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                    onClick={() => setCollapsed(!collapsed)}
                    className={'trigger-btn'}
                />
            </Sider>
            <Layout>
                <HeaderComponent/>
                <div className={'home-page-content'}>
                    <Row align={"middle"}>
                        <Col span={'21'} className={'content-title'}>
                            <h1>Defect Management</h1>
                        </Col>
                    </Row>
                    <Row gutter={'16'} className={'search-sort-container'}>
                        <Col span={'18'}>
                            <Search
                                placeholder={'Search by title, repository, reporter, or assignee'}
                                className={'search'}
                                allowClear
                                onChange={(e) => handleSearch(e.target.value)}
                                style={{borderRadius: '25px', height: '40px', width: '100%'}}
                            />
                        </Col>
                        <Col span={'4'}>
                            <Select
                                defaultValue={'name'}
                                className={'select-sort'}
                                onChange={handleSort}
                                style={{width: '100%'}}>
                                <Option value={'name'}>Name</Option>
                                <Option value={'created'}>Created</Option>
                                <Option value={'priority'}>Priority</Option>
                                <Option value={'severity'}>Severity</Option>
                            </Select>
                        </Col>
                        <Col span={'2'}>
                            <Button
                                type={'text'}
                                icon={typeSort ? <SortAscendingOutlined/> : <SortDescendingOutlined/>}
                                onClick={() => {
                                    setTypeSort(!typeSort);
                                    handleSort(sortField);
                                }}
                                className={'sort-btn'}
                                style={{width: '78%'}}
                            />
                        </Col>
                    </Row>
                </div>
                <div className={'defect-content'}>
                    <Table
                        columns={columns}
                        dataSource={defects}
                        loading={loading}
                        scroll={{x: true}}
                    />
                </div>
            </Layout>
        </Layout>
    );
}

export default DefectIndividual;