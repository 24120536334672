import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import RepositoryDetail from "./Repository/RepositoryDetail";
import Home from "./Repository/Home";
import DefectIndividual from "./Defect/DefectIndividual";
import Login from "./Auth/Login";
import Profile from "./Profile/Profile";
import Branches from "./Repository/Branches";
import Commits from "./Repository/Commits";
import Members from "./Repository/Members";
import ActivityChartProject from "./Analytics/ActivityChartProject";
import ContributeChartProject from "./Analytics/ContributeChartProject";
import {AuthProvider} from "./Auth/AuthConext";
import ProtectedRoute from "./Auth/ProtectedRoute"
import ReviewCode from "./Repository/ReviewCode";
import CommitCode from "./Repository/CommitCode";
import Defects from "./Defect/Defects";
import UpdateDefect from "./Defect/UpdateDefect";
import DefectRepositoryDetail from "./Defect/DefectRepositoryDetail";
import Users from "./User/Users";
import UserDetail from "./User/UserDetail";
import ForgotPassword from "./Auth/ForgotPassword";
import RootProtectedRoute from "./Auth/RootProtectedRoute";

function App() {
    return (
        <>
            <AuthProvider>
            <Router>
                <Routes>
                    <Route path={'/login'} element={<Login/>}/>
                    <Route path={'/edit-profile'} element={<ProtectedRoute element={<Profile/>}/>}/>
                    <Route path={'/'} element={<ProtectedRoute element={Home}/>}/>
                    <Route path={`/repository/:id/commit/code`} element={<CommitCode/>}/>
                    <Route path={`/repository/:repositoryName/defect`} element={<Defects/>}/>
                    <Route path={`/repository/:repositoryName/defect/:defectId`} element={<DefectRepositoryDetail/>}/>
                    <Route path={`/repository/:id/defect/:defectId/update`} element={<UpdateDefect/>}/>
                    <Route path={'/defectIndividual'} element={<DefectIndividual/>}/>
                    <Route path={'/profile'} element={<ProtectedRoute element={Profile}/>}/>
                    <Route path={'/:host'} element={<ProtectedRoute element={Home}/>}/>
                    <Route path={'/repository/:repositoryName'} element={<ProtectedRoute element={RepositoryDetail}/>}/>
                    <Route path={'/repository/:repositoryName/tree/:selectedBranch/*'} element={<ProtectedRoute element={RepositoryDetail}/>}/>
                    <Route path={'/repository/:repositoryName/blob/:selectedBranch/*'} element={<ProtectedRoute element={ReviewCode}/>}/>
                    <Route path={`/repository/:repositoryName/branch`} element={<ProtectedRoute element={Branches}/>}/>
                    <Route path={`/repository/:repositoryName/commit`} element={<ProtectedRoute element={Commits}/>}/>
                    <Route path={`/repository/:repositoryName/member`} element={<ProtectedRoute element={Members}/>}/>
                    <Route path={`/repository/:repositoryName/activity`} element={<ProtectedRoute element={ActivityChartProject}/>}/>
                    <Route path={`/repository/:repositoryName/contribute`} element={<ProtectedRoute element={ContributeChartProject}/>}/>
                    <Route path={'/defectIndividual'} element={<ProtectedRoute element={DefectIndividual}/>}/>
                    <Route path={'/user'} element={<RootProtectedRoute element={Users}/>}/>
                    <Route path={'/user/:username'} element={<RootProtectedRoute element={UserDetail}/>}/>
                    <Route path={'/forgot_password'} element={<ForgotPassword/>}/>
                </Routes>
            </Router>
            </AuthProvider>
        </>
    )
}

export default App;
