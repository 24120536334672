import React, { useState, useEffect } from 'react';
import { Avatar, Button, List, message } from 'antd';
import { SendOutlined, UserOutlined } from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import { Comment } from '@ant-design/compatible';
import api from '../Ultils/Api';
import dayjs from 'dayjs';

function DefectComment({ defectId, host, repositoryName }) {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (defectId) {
            fetchComments();
        }
    }, [defectId]);

    const fetchComments = async () => {
        setLoading(true);
        try {
            const response = await api.get(
                `/defects/${defectId}/comments`
            );
            const formattedComments = response.data.map(comment => ({
                id: comment.id,
                author: comment.createdBy,
                content: comment.content,
                timestamp: dayjs(comment.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                avatar: <UserOutlined />
            }));
            setComments(formattedComments);
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddComment = async () => {
        if (newComment.trim() === '') {
            return;
        }

        setSubmitting(true);
        try {
            const response = await api.post(
                `/defects/${defectId}/comments`,
                { content: newComment }
            );

            // Add new comment to list with response data
            const newCommentData = {
                id: response.data.id,
                author: response.data.createdBy,
                content: response.data.content,
                timestamp: dayjs(response.data.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                avatar: <UserOutlined />
            };
            setComments([...comments, newCommentData]);
            setNewComment('');
            message.success('Comment added successfully');
        } catch (error) {
            handleApiError(error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleApiError = (error) => {
        console.error('API Error:', error);
        if (error.response?.status === 401) {
            message.error('Session expired. Please login again.');
            // Handle navigation to login if needed
        } else {
            message.error('Operation failed. Please try again.');
        }
    };

    return (
        <div className="comment-container">
            <List
                className='comments-list'
                itemLayout='horizontal'
                dataSource={comments}
                loading={loading}
                renderItem={(comment) => (
                    <Comment
                        author={<span className="comment-author">{comment.author}</span>}
                        avatar={comment.avatar}
                        content={<p>{comment.content}</p>}
                        datetime={<span>{comment.timestamp}</span>}
                    />
                )}
            />
            <div className="comment-input-section">
                <div className="comment-input-wrapper">
                    <Avatar icon={<UserOutlined />} />
                    <div className="textarea-wrapper">
                        <TextArea
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Add your comment..."
                            autoSize={{ minRows: 3 }}
                            disabled={submitting}
                        />
                        <Button
                            type="primary"
                            icon={<SendOutlined />}
                            onClick={handleAddComment}
                            className="submit-button"
                            loading={submitting}
                        >
                            Add Comment
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DefectComment;