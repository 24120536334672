import React, {useState, useEffect} from "react";
import {Button, Card, Input, Typography, message} from "antd";
import EditProfile from "./EditProfile";
import {useAuth} from "../Auth/AuthConext";
import api from "../Ultils/Api";

function AccountDetails() {
    const {Text} = Typography;
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const {token} = useAuth();
    const [userInfo, setUserInfo] = useState({
        name: "",
        gender: "",
        email: "",
        dateOfBirth: "",
        country: "",
        phoneNumber: ""
    });

    useEffect(() => {
        fetchProfile();
    }, []);

    const fetchProfile = async () => {
        try {
            const response = await api.get('git/profile', {
                headers: {Authorization: token}
            });

            // Map API response to component state
            setUserInfo({
                name: response.data.real_name || "",
                gender: response.data.gender || "",
                email: response.data.email || "",
                dateOfBirth: response.data.date_of_birth || "",
                country: response.data.country || "",
                phoneNumber: response.data.phone_number || ""
            });
        } catch (error) {
            message.error('Failed to load profile information');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleSave = async (updatedInfo) => {
        try {
            const requestBody = {
                realName: updatedInfo.name,
                gender: updatedInfo.gender,
                email: updatedInfo.email,
                dateOfBirth: updatedInfo.dateOfBirth,
                country: updatedInfo.country,
                phone: updatedInfo.phoneNumber
            };

            await api.put('git/update-profile',
                requestBody,
                {headers: {Authorization: token}}
            );

            setUserInfo(updatedInfo);
            setIsEditing(false);
            message.success('Profile updated successfully');
        } catch (error) {
            message.error('Failed to update profile');
            console.error('Error:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if(isEditing) {
        return <EditProfile
            userInfo={userInfo}
            onSave={handleSave}
            onCancel={handleCancel}
        />;
    }

    return (
        <>
            <Card title={'Information'} className={'profile-information-card ant-card'}>
                <div className={'profile-information-content'}>
                    <div className={'profile-information-grid'}>
                        <div className={'profile-information-item'}>
                            <Text className={'profile-information-label'}>Name:</Text>
                            <Input
                                readOnly
                                className={'profile-information-input'}
                                value={userInfo.name}
                            />
                        </div>
                        <div className={'profile-information-item'}>
                            <Text className={'profile-information-label'}>Gender:</Text>
                            <Input
                                readOnly
                                className={'profile-information-input'}
                                value={userInfo.gender}
                            />
                        </div>
                    </div>
                    <div className={'profile-information-item'}>
                        <Text className={'profile-information-label'}>Email:</Text>
                        <Input
                            readOnly
                            className={'profile-information-input'}
                            value={userInfo.email}
                        />
                    </div>
                    <div className={'profile-information-item'}>
                        <Text className={'profile-information-label'}>Date of birth:</Text>
                        <Input
                            readOnly
                            className={'profile-information-input'}
                            value={userInfo.dateOfBirth}
                        />
                    </div>
                    <div className={'profile-information-item'}>
                        <Text className={'profile-information-label'}>Country:</Text>
                        <Input
                            readOnly
                            className={'profile-information-input'}
                            value={userInfo.country}
                        />
                    </div>
                    <div className={'profile-information-item'}>
                        <Text className={'profile-information-label'}>Phone number:</Text>
                        <Input
                            readOnly
                            className={'profile-information-input'}
                            value={userInfo.phoneNumber}
                        />
                    </div>
                    <div className={'profile-button-container'}>
                        <Button
                            className={'profile-button'}
                            onClick={handleEdit}
                            type={'primary'}
                        >
                            Edit
                        </Button>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default AccountDetails;