import React, {useState} from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import {useNavigate, useParams} from "react-router-dom";
import {Tabs, List, Select} from "antd";
function ActivityChartProject() {
    const {id} = useParams();
    const [activeTab,setActiveTab] = useState('all');
    const {Option} = Select;
    const navigate = useNavigate();
    const { repositoryName } = useParams();
    const handleTabChange= (key) => {
        setActiveTab(key);
    }
    const logActivityProject = [
        {
            id: 1,
            type: 'commit',
            user: 'Vu Duc Phong',
            time: '2024/10/18 10:30',
            description: 'Committed 3 changes to branch "PhongVD"',
            branch: 'PhongVD',
        },
        {
            id: 2,
            type: 'defect',
            user: 'Bui Viet Hung',
            time: '2024/10/17 13:20',
            description: 'Opened defect #2: "Fix define role"',
            defectNumber: 2,
            issueTitle: 'Fix define role'
        },
        {
            id: 3,
            type: 'commit',
            user: 'Nguyen Hong Quan',
            time: '2024/10/18 16:05',
            description: 'Committed 2 changes to branch "QuanNH"',
            branch: 'QuanNH',
        },
        {
            id: 4,
            type: 'branch',
            user: 'Nguyen Hong Quan',
            time: '2024/10/18 20:05',
            description: 'Create Branch "ABC"',
        },
    ]
    const filteredData = logActivityProject.filter(item => {
        if(activeTab === 'all') return true;
        if(activeTab === 'branchEvents' && item.type === 'branch') return true;
        if(activeTab === 'commitEvents' && item.type === 'commit') return true;
        if(activeTab === 'defectEvents' && item.type === 'defect') return true;
        return false;
    })

    const handleSelectChange = (value) => {
        // Check if repositoryName is defined
        if (!repositoryName) {
            console.error('Repository name is undefined');
            return;
        }

        // Build the full path using template literals to ensure repositoryName is included
        const basePath = `/repository/${repositoryName}`;

        switch (value) {
            case 'activityChart':
                navigate(`${basePath}/activity`);
                break;
            case 'contributeChart':
                navigate(`${basePath}/contribute`);
                break;
            case 'listMember':
                navigate(`${basePath}/member`);
                break;
            default:
                break;
        }
    };
    return (
        <>
            <LayoutComponentDetail>
                <div className={'content-title'}>
                    <h1>Activity Repository {id}</h1>
                </div>
                <div className={'activity-chart-project-container'}>
                    <div className={'activity-action'}>
                        <Select defaultValue={'activityChart'} onChange={handleSelectChange} style={{width: '160px'}}>
                            <Option value={'listMember'}>List Member</Option>
                            <Option value={'activityChart'}>Activity</Option>
                            <Option value={'contributeChart'}>Contribute Chart</Option>
                        </Select>
                    </div>
                    <div className={'activity-chart-project-action'}>
                        <Tabs
                            activeKey={activeTab}
                            onChange={handleTabChange}
                            items={[
                                {key: 'all', label: 'All'},
                                {key: 'branchEvents', label: 'Branch Events'},
                                {key: 'commitEvents', label: 'Commit Events'},
                                {key: 'defectEvents', label: 'Defect Events'}
                            ]}
                        />
                    </div>
                    <div className={'activity-chart-project-log'}>
                        <List
                            itemLayout={'horizontal'}
                            dataSource={filteredData}
                            renderItem={(item) => (
                                <List.Item.Meta
                                    title={`${item.user} - ${item.time}`}
                                    description={item.description}
                                />
                            )}
                        />
                    </div>
                </div>
            </LayoutComponentDetail>
        </>
    )
}

export default ActivityChartProject;