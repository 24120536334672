import React, { useState, useEffect } from "react";
import { Card, Table, Tooltip } from "antd";
import _ from 'lodash';
import api from "../Ultils/Api";

function ActivityChart() {
    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchActivityData();
    }, []);

    const fetchActivityData = async () => {
        try {
            const response = await api.get('/git/activity');
            const processedData = processActivityData(response.data.contributions || []);
            setActivityData(processedData);
        } catch (error) {
            console.error('Error fetching activity data:', error);
        } finally {
            setLoading(false);
        }
    };

    const processActivityData = (contributions) => {
        const groupedByDate = _.groupBy(contributions, 'date');
        return Object.entries(groupedByDate).map(([date, commits]) => ({
            date,
            count: commits.length,
            details: commits.map(c => ({
                type: 'commit',
                message: c.details.message,
                repository: c.details.repository
            }))
        }));
    };

    const logColumns = [
        {title: 'Date', dataIndex: 'date', key: 'date', width: '25%'},
        {title: 'Activity', dataIndex: 'count', key: 'activity', width: '20%'},
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
            width: '55%',
            render: (details) => (
                details.map((detail, index) => (
                    <div key={index}>
                        {`${detail.type} - ${detail.message} (${detail.repository})`}
                    </div>
                ))
            )
        }
    ];

    const getColor = (value) => {
        switch(value) {
            case 0: return '#ebedf0';
            case 1: return '#f89c7c';
            case 2: return '#fd7440';
            case 3: return '#d63a01';
            default: return '#ebedf0';
        }
    };

    const calculateActivityLevel = (count) => {
        if (count === 0) return 0;
        if (count <= 2) return 1;
        if (count <= 4) return 2;
        return 3;
    };

    const getActivityForDate = (searchDate) => {
        const activity = activityData.find(item => item.date === searchDate);
        return activity ? calculateActivityLevel(activity.count) : 0;
    };

    // Generate array of last 13 months
    const generateMonthsArray = () => {
        const months = [];
        const currentDate = new Date();
        currentDate.setDate(1); // Set to first of month

        for (let i = 12; i >= 0; i--) {
            const date = new Date(currentDate);
            date.setMonth(currentDate.getMonth() - i);
            months.push({
                name: date.toLocaleString('en-US', { month: 'short' }),
                year: date.getFullYear(),
                month: date.getMonth()
            });
        }
        return months;
    };

    const months = generateMonthsArray();
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    const getWeeksInMonth = (year, month) => {
        const firstDay = new Date(year, month, 1).getDay(); // Ngày đầu tiên của tháng
        const lastDate = new Date(year, month + 1, 0).getDate(); // Số ngày trong tháng
        return Math.ceil((firstDay + lastDate) / 7); // Tổng số tuần
    };


    const getDateString = (monthData, dayIndex, week) => {
        const firstDate = new Date(monthData.year, monthData.month, 1);
        const adjustedDayIndex = (dayIndex + 1) % 7; // Đảm bảo ngày bắt đầu từ thứ Hai

        // Bắt đầu từ ngày đầu tiên của tháng, tính toán ngày theo tuần và chỉ số ngày
        const dayOfMonth = firstDate.getDate() + (week * 7) + adjustedDayIndex - firstDate.getDay() + 1;

        const date = new Date(monthData.year, monthData.month, dayOfMonth);

        // Đảm bảo chỉ trả về ngày trong tháng hiện tại
        if (date.getMonth() === monthData.month) {
            return date.toISOString().split('T')[0];
        }
        return null;
    };




    return (
        <>
            <div className="activity-container">
                <Card title="Activity" className="activity-heatmap-card">
                    <div className="heatmap-container">
                        <div className="day-labels">
                            {days.map(day => (
                                <div key={day} className="day-label">{day}</div>
                            ))}
                        </div>

                        <div className="activity-grid">
                            {months.map((monthData, monthIndex) => {
                                const weeksInMonth = getWeeksInMonth(monthData.year, monthData.month);
                                return (
                                    <div key={monthIndex} className="month-column">
                                        <div className="month-label">
                                            {monthData.name}
                                        </div>

                                        <div className="activity-column">
                                            <div className="activity-days">
                                                {days.map((day, dayIndex) => (
                                                    <div key={dayIndex} className="activity-weeks">
                                                        {[...Array(weeksInMonth)].map((_, week) => {
                                                            const dateString = getDateString(monthData, dayIndex, week);
                                                            if (!dateString) return null;

                                                            const value = getActivityForDate(dateString);
                                                            return (
                                                                <Tooltip
                                                                    key={week}
                                                                    title={`${value} contributions on ${dateString}`}
                                                                >
                                                                    <div
                                                                        className="activity-cell"
                                                                        style={{
                                                                            backgroundColor: getColor(value),
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            );
                                                        })}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="heatmap-legend">
                        <span>Less</span>
                        {[0, 1, 2, 3].map((value) => (
                            <div
                                key={`legend-${value}`}
                                className="legend-cell"
                                style={{
                                    backgroundColor: getColor(value),
                                }}
                            />
                        ))}
                        <span>More</span>
                    </div>
                </Card>

                <Card className="activity-log-card">
                    <Table
                        columns={logColumns}
                        dataSource={activityData}
                        size="middle"
                        loading={loading}
                        rowKey="date"
                        pagination={{
                            pageSize: 10,
                            showSizeChanger: true
                        }}
                    />
                </Card>
            </div>
        </>
    );
}

export default ActivityChart;