import React, {useState} from "react";
import LayoutComponent from "../Layout/LayoutComponent";
import {Avatar, Button,List} from "antd";
import {BarChartOutlined, CameraOutlined, LockOutlined, LogoutOutlined, UserOutlined} from "@ant-design/icons";
import AccountDetails from "./AccountDetails";
import {LogOut} from "lucide-react";
import ChangePassword from "./ChangePassword";
import ActivityChart from "./ActivityChart";
import {useAuth} from "../Auth/AuthConext";
import Login from "../Auth/Login";
import {useNavigate} from "react-router-dom";
function Profile() {
    const {logout} = useAuth();
    const navigate = useNavigate()
    const [selectedMenu,setSelectedMenu] = useState('accountDetails');
    const menuItems = [
        {label: 'Account Details', key: 'accountDetails',icon:<UserOutlined/>},
        {label: 'Change Password', key: 'changePassword',icon:<LockOutlined/>},
        {label: 'Activity Chart', key: 'activityChart',icon: <BarChartOutlined/>},
        {label: 'Log out', key: 'logOut',icon: <LogoutOutlined/>}
    ];
    const handleMenuClick = (key) => {
        if(key === 'logOut') {
            logout();
            navigate('/login')
        }else {
            setSelectedMenu(key);
        }
    };
    const renderContent = () => {
        switch (selectedMenu) {
            case 'accountDetails':
                return <AccountDetails/>;
            case 'changePassword':
                return <ChangePassword/>;
            case 'activityChart':
                return <ActivityChart/>;
            case 'logOut':
                return <Login/>
            default:
                return <AccountDetails/>;
        }
    }
    return(
        <>
            <LayoutComponent>
                <div className={'profile-wrapper'}>
                    <div className={'profile-left'}>
                        <div className={'profile-left-sidebar'}>
                            <div className={'profile-avatar-section'}>
                                <div className={'content-title'}>
                                    <h1>Profile</h1>
                                </div>
                                <Avatar size={180} className={'profile-avatar'}/>
                                <Button className={'profile-change-avatar'} icon={<CameraOutlined/>}>
                                    Change Avatar
                                </Button>
                            </div>
                            <div className={'profile-menu-list'}>
                                <List
                                    dataSource={menuItems}
                                    renderItem={(item) => (
                                        <List.Item
                                            className={`profile-menu-item ${selectedMenu === item.key ? 'profile-menu-item-active' : ''}`}
                                            onClick={() => handleMenuClick(item.key)}
                                        >
                                            <span className={'profile-menu-icon'}>{item.icon}</span>
                                            {item.label}
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'profile-right'}>
                        {renderContent()}
                    </div>
                </div>
            </LayoutComponent>
        </>
    )
}

export default Profile;