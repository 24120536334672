import React, { useEffect, useState } from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import { Button, Select, Dropdown, Menu, Modal, Form, message, Spin } from "antd";
import {
    BranchesOutlined,
    CopyOutlined,
    DownOutlined,
    HistoryOutlined,
    ScheduleOutlined,
    UserOutlined,
    ArrowLeftOutlined
} from "@ant-design/icons";
import Input from "antd/es/input/Input";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import api from "../Ultils/Api";
import FileTable from "../Shared/FileTable";

const { Option } = Select;

// Separate MenuClone component for better organization
const MenuClone = ({ repositoryName }) => {
    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            message.success('Copied to clipboard!');
        } catch (err) {
            message.error('Failed to copy');
            console.error('Copy failed:', err);
        }
    };

    const getCloneUrls = () => {
        // You can adjust these URLs based on your actual domain/repository structure
        const sshUrl = `git@git.dxgitauto.xyz:${repositoryName}.git`;
        const httpsUrl = `https://git.dxgitauto.xyz/git/${repositoryName}.git`;
        return { sshUrl, httpsUrl };
    };

    const { sshUrl, httpsUrl } = getCloneUrls();

    return (
        <Menu onClick={e => e.domEvent.stopPropagation()}>
            <Menu.Item key="ssh" onClick={e => e.domEvent.stopPropagation()}>
                <div>
                    Clone with SSH
                    <div className="repository-action-clone-item">
                        <Input value={sshUrl} readOnly />
                        <Button
                            icon={<CopyOutlined />}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCopy(sshUrl);
                            }}
                        />
                    </div>
                </div>
            </Menu.Item>
            <Menu.Item key="https" onClick={e => e.domEvent.stopPropagation()}>
                <div>
                    Clone with HTTPS
                    <div className="repository-action-clone-item">
                        <Input value={httpsUrl} readOnly />
                        <Button
                            icon={<CopyOutlined />}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCopy(httpsUrl);
                            }}
                        />
                    </div>
                </div>
            </Menu.Item>
        </Menu>
    );
};

function RepositoryDetail() {
    // State declarations
    const [isModalVisibleCreate, setIsModalVisibleCreate] = useState(false);
    const [newBranchName, setNewBranchName] = useState('');
    const [createFrom, setCreateFrom] = useState('main');
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [repoInfo, setRepoInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [branchesLoading, setBranchesLoading] = useState(false);
    const [list, setList] = useState([]);
    const [selectedFile, setSelectedFile] = useState('');

    // Hooks
    const navigate = useNavigate();
    const location = useLocation();
    const { repositoryName, host } = useParams();

    // API error handler
    const handleApiError = (error) => {
        console.error('API Error:', error);
        if (error.response?.status === 401) {
            message.error('Session expired. Please login again.');
            navigate('/login');
        } else {
            message.error(error.response?.data?.message || 'Operation failed. Please try again.');
        }
    };

    // Fetch repository files
    const fetchFile = async () => {
        setLoading(true);
        try {
            const repoResponse = await api.get(`/git/repositories/${repositoryName}/info`);
            setRepoInfo(repoResponse.data);

            const pathParts = location.pathname.split('/');
            const branchIndex = pathParts.indexOf(selectedBranch);
            const currentPath = branchIndex !== -1 ? pathParts.slice(branchIndex + 1).join('/') : '';

            const listFileResponse = await api.get(
                `/git/repositories/${repositoryName}/branches/${selectedBranch}/files/${currentPath}`
            );
            const listFile1 = listFileResponse.data;

            if (listFile1) {
                const isFileView = location.pathname.includes('/blob/');

                if (isFileView && listFile1.length === 1) {
                    const file = listFile1[0];
                    try {
                        const contentResponse = await api.get(
                            `/git/repositories/${repositoryName}/branches/${selectedBranch}/files/${file.path}`
                        );

                        const fileData = {
                            key: 0,
                            name: file.path.split('/').pop(),
                            lastCommit: file.lastCommit,
                            lastUpdate: file.lastUpdate,
                            type: file.type || 'unknown',
                            path: file.path || '',
                            content: contentResponse.data.content
                        };

                        setList([fileData]);
                        navigate(`/${repositoryName}/review-code`, {
                            state: {
                                fileData: fileData,
                                repositoryName,
                                selectedBranch
                            }
                        });
                    } catch (contentError) {
                        console.error('Error fetching file content:', contentError);
                        message.error('Failed to load file content');
                    }
                } else {
                    if (Array.isArray(listFile1)) {
                        const listFileRes = listFile1.map((file, index) => ({
                            key: index,
                            name: file.path.split('/').pop(),
                            lastCommit: file.lastCommit,
                            lastUpdate: file.lastUpdate,
                            type: file.type || 'unknown',
                            path: file.path || '',
                        }));
                        setList(listFileRes);
                    } else {
                        console.error('listFile1 is not an array:', listFile1);
                        setList([]);
                    }
                }
            } else {
                setList([]);
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
        }
    };

    // Handle file/directory click
    const handleNameClick = async (record) => {
        const pathParts = location.pathname.split('/');
        const isInFileView = pathParts.includes('tree') || pathParts.includes('blob');
        const branchIndex = pathParts.indexOf(selectedBranch);
        const currentPath = isInFileView && branchIndex !== -1
            ? pathParts.slice(branchIndex + 1).join('/')
            : '';

        const newPath = currentPath
            ? `${currentPath}/${record.name}`
            : record.name;

        setSelectedFile(newPath);

        if (record.type === 'directory' || record.type === 'dir') {
            navigate(`/repository/${repositoryName}/tree/${selectedBranch}/${newPath}`);
        } else {
            try {
                setLoading(true);
                const contentResponse = await api.get(
                    `/git/repositories/${repositoryName}/branches/${selectedBranch}/files/${record.path}`
                );

                const fileData = {
                    ...record,
                    content: contentResponse.data.content
                };

                navigate(`/repository/${repositoryName}/blob/${selectedBranch}/${record.path}`, {
                    state: {
                        fileData,
                        repositoryName,
                        selectedBranch
                    }
                });
            } catch (error) {
                handleApiError(error);
            } finally {
                setLoading(false);
            }
        }
    };

    // Handle back button click
    const handleBack = () => {
        const pathParts = location.pathname.split('/');
        if (pathParts.includes('tree') || pathParts.includes('blob')) {
            const branchIndex = pathParts.indexOf(selectedBranch);
            const currentPathParts = pathParts.slice(branchIndex + 1);

            if (currentPathParts.length > 0) {
                const parentPathParts = currentPathParts.slice(0, -1);
                if (parentPathParts.length > 0) {
                    navigate(`/repository/${repositoryName}/tree/${selectedBranch}/${parentPathParts.join('/')}`);
                    setSelectedFile(parentPathParts.join('/'));
                } else {
                    navigate(`/repository/${repositoryName}/tree/${selectedBranch}`);
                    setSelectedFile('');
                }
            } else {
                navigate(`/repository/${repositoryName}`);
                setSelectedFile('');
            }
        } else {
            navigate('/');
        }
    };

    // Fetch branches
    const fetchBranches = async () => {
        setBranchesLoading(true);
        try {
            const branchResponse = await api.get(`/git/repositories/${repositoryName}/branches`);
            const branchData = branchResponse.data;

            if (branchData && branchData[repositoryName]) {
                const branchList = branchData[repositoryName].map(branch => branch.name);
                setBranches(branchList);

                if (!selectedBranch) {
                    const defaultBranch = branchList.find(b => b === 'main' || b === 'master') || branchList[0];
                    setSelectedBranch(defaultBranch);
                    setCreateFrom(defaultBranch);
                }
            } else {
                message.warning('Branch data structure is unexpected');
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setBranchesLoading(false);
        }
    };

    // Fetch repository data
    const fetchRepositoryData = async () => {
        setLoading(true);
        try {
            const repoResponse = await api.get(`/git/repositories/${repositoryName}/info`);
            const repoInformation = repoResponse.data;
            setRepoInfo(repoInformation);

            const listResponse = await api.get(
                `/git/repositories/${repositoryName}/branches/${selectedBranch}/files`
            );
            const listData = listResponse.data;

            if (listData && listData.files) {
                const listFile = listData.files.map((file, index) => ({
                    key: index,
                    name: file.name,
                    lastCommit: file.lastCommit,
                    lastUpdate: file.lastUpdate,
                    type: file.type || file.type,
                    path: file.path || file.path
                }));
                setList(listFile);
            } else {
                setList([]);
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
        }
    };

    // Handle branch creation
    const handleCreateBranch = async (e) => {
        e.preventDefault();
        if (!newBranchName || !createFrom) {
            message.error('Please fill in all required fields');
            return;
        }

        try {
            const response = await api.post(`/git/repositories/${repositoryName}/branches`, {
                sourceBranch: createFrom,
                newBranch: newBranchName
            });

            if (response.data) {
                message.success('Branch created successfully');
                setIsModalVisibleCreate(false);
                setNewBranchName('');
                setCreateFrom(selectedBranch);
                await fetchBranches();
            }
        } catch (error) {
            console.error('Error creating branch:', error);
            message.error(error.response?.data || 'Failed to create branch');
        }
    };

    // Effects
    useEffect(() => {
        if (repositoryName) {
            fetchBranches();
        }
    }, [repositoryName]);

    useEffect(() => {
        if (repositoryName && selectedBranch) {
            if (selectedFile) {
                fetchFile();
            } else {
                fetchRepositoryData();
            }
        }
    }, [repositoryName, selectedBranch, selectedFile, location.pathname]);

    // Render
    return (
        <LayoutComponentDetail>
            <Spin spinning={branchesLoading} tip="Loading repository...">
                <div className="content-title">
                    <Button
                        icon={<ArrowLeftOutlined />}
                        onClick={handleBack}
                        style={{ marginRight: '16px' }}
                    >
                        Back
                    </Button>
                    <h1>{host}/{repositoryName}</h1>
                </div>
                <div className="repository-detail-action">
                    <Select
                        value={selectedBranch}
                        style={{ width: '110px' }}
                        onChange={(value) => setSelectedBranch(value)}
                        loading={branchesLoading}
                    >
                        {branches.map((branch, index) => (
                            <Option key={`branch${index + 1}`} value={branch}>{branch}</Option>
                        ))}
                    </Select>
                    <div className="action2">
                        <Button onClick={() => setIsModalVisibleCreate(true)}>Create Branch</Button>
                        <Modal
                            title="Create Branch"
                            visible={isModalVisibleCreate}
                            onOk={handleCreateBranch}
                            onCancel={() => setIsModalVisibleCreate(false)}
                            okText="Create"
                            cancelText="Cancel"
                            okButtonProps={{ className: 'custom-ok-button' }}
                        >
                            <Form layout="vertical">
                                <Form.Item
                                    label="Branch Name:"
                                    name="branchName"
                                    rules={[
                                        { required: true, message: 'Please input branch name' },
                                        { max: 30, message: 'Branch name cannot be more than 30 characters long' }
                                    ]}
                                    validateTrigger="onBlur"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Branch Name"
                                        value={newBranchName}
                                        onChange={(e) => setNewBranchName(e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Create From:"
                                    name="createFrom"
                                    validateTrigger="onBlur"
                                >
                                    <Select
                                        defaultValue={selectedBranch}
                                        onChange={(value) => setCreateFrom(value)}
                                    >
                                        {branches.map((branch, index) => (
                                            <Option key={`branch${index + 1}`} value={branch}>{branch}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Dropdown
                            overlay={<MenuClone repositoryName={repositoryName} />}
                            trigger={['click']}
                            className="repository-action-clone"
                            destroyPopupOnHide
                        >
                            <Button className="custom-ok-button">
                                Clone <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
                <div className="repository-detail-container">
                    <FileTable
                        list={list}
                        loading={loading}
                        onNameClick={handleNameClick}
                    />
                    <div className="repository-information">
                        <Spin spinning={loading}>
                            <h1>Information</h1>
                            <div className="repository-information-items">
                                <div className="repository-information-item">
                                    <p className="repository-information-title">
                                        <ScheduleOutlined /> Created
                                    </p>
                                    <p>{repoInfo[repositoryName]?.created}</p>
                                </div>
                                <p>-------------------------------------</p>
                                <div className="repository-information-item">
                                    <p className="repository-information-title">
                                        <UserOutlined /> Creator
                                    </p>
                                    <p>{repoInfo[repositoryName]?.creator}</p>
                                </div>
                                <div className="repository-information-item">
                                    <p>
                                        <HistoryOutlined /> {repoInfo[repositoryName]?.numCommits} commits
                                    </p>
                                </div>
                                <div className="repository-information-item">
                                    <p>
                                        <BranchesOutlined /> {repoInfo[repositoryName]?.numBranches} branches
                                    </p>
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
            </Spin>
        </LayoutComponentDetail>
    );
}

export default RepositoryDetail;