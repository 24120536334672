import React from 'react';
import { List, Avatar, Badge, Tooltip } from 'antd';
import { UserOutlined, SaveOutlined } from '@ant-design/icons';

const ActiveUsersList = ({ users, savePermissionHolder }) => {
    const stringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        const color = Math.abs(hash).toString(16).substring(0, 6);
        return `#${'0'.repeat(6 - color.length)}${color}`;
    };

    // Sort users to put save permission holder first
    const sortedUsers = Array.from(users.entries()).sort((a, b) => {
        if (a[1].username === savePermissionHolder) return -1;
        if (b[1].username === savePermissionHolder) return 1;
        return b[1].joinedAt - a[1].joinedAt;  // Sort others by join time
    });

    return (
        <div className="active-users-list p-4 bg-white rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-4">
                Active Users ({users.size})
            </h3>
            <List
                itemLayout="horizontal"
                dataSource={sortedUsers}
                renderItem={([userId, userData]) => (
                    <List.Item
                        className={userData.username === savePermissionHolder ? 'bg-blue-50' : ''}
                    >
                        <List.Item.Meta
                            avatar={
                                <Badge
                                    dot
                                    color="green"
                                    offset={[-5, 32]}
                                >
                                    <Avatar
                                        icon={<UserOutlined />}
                                        style={{
                                            backgroundColor: stringToColor(userId)
                                        }}
                                    />
                                </Badge>
                            }
                            title={
                                <div className="flex items-center">
                                    <span className="font-medium">
                                        {userData.username}
                                    </span>
                                    {savePermissionHolder === userData.username && (
                                        <Tooltip title="Can save changes">
                                            <SaveOutlined
                                                className="ml-2 text-blue-500"
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                            }
                            description={
                                <span className="text-gray-500 text-sm">
                                    Joined at {new Date(userData.joinedAt).toLocaleTimeString()}
                                </span>
                            }
                        />
                    </List.Item>
                )}
            />
        </div>
    );
};

export default ActiveUsersList;