import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from "js-cookie";
import {useAuth} from "./AuthConext";

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const { isLoggedIn } = useAuth();
    const token = Cookies.get('token');

    if (!token || !isLoggedIn) {
        return <Navigate to="/login" />;
    }

    return <Component {...rest} />;
};

export default ProtectedRoute;
