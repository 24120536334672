import React, { useState, useEffect } from "react";
import {
    CheckCircleOutlined, ClockCircleOutlined,
    CommentOutlined,
    ExclamationOutlined,
    FileAddOutlined, InfoCircleOutlined, ScheduleOutlined, SolutionOutlined,
    UserOutlined
} from "@ant-design/icons";
import { Timeline, message } from "antd";
import api from '../Ultils/Api';
import dayjs from 'dayjs';

function DefectActivityStream({ defectId, host, repositoryName }) {
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (defectId) {
            fetchHistory();
        }
    }, [defectId]);

    const fetchHistory = async () => {
        setLoading(true);
        try {
            const response = await api.get(
                `/defects/${defectId}/history`
            );

            const formattedActivities = response.data.map(history => ({
                id: history.id,
                type: getActivityType(history.fieldName),
                user: history.changedBy,
                action: getActionText(history.fieldName),
                value: history.newValue,
                time: dayjs(history.changedAt).format('YYYY-MM-DD HH:mm:ss'),
                oldValue: history.oldValue
            }));

            setActivities(formattedActivities);
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const getActivityType = (fieldName) => {
        switch (fieldName.toLowerCase()) {
            case 'status':
                return 'status';
            case 'comment':
                return 'comment';
            case 'assigned_to':
                return 'assignment';
            case 'priority':
                return 'priority';
            case 'severity':
                return 'severity';
            case 'deadline':
                return 'deadline';
            case 'solution':
                return 'solution';
            default:
                return 'other';
        }
    };

    const getActionText = (fieldName) => {
        switch (fieldName.toLowerCase()) {
            case 'status':
                return 'changed status to';
            case 'comment':
                return 'commented';
            case 'assigned_to':
                return 'assigned to';
            case 'priority':
                return 'changed priority to';
            case 'severity':
                return 'changed severity to';
            case 'deadline':
                return 'changed deadline to';
            case 'solution':
                return 'added solution';
            default:
                return 'modified';
        }
    };

    const getActivityIcon = (type) => {
        switch (type) {
            case 'status':
                return <CheckCircleOutlined className={'activity-icon-status'}/>;
            case 'comment':
                return <CommentOutlined className={'activity-icon-comment'}/>;
            case 'attachment':
                return <FileAddOutlined className={'activity-icon-attachment'}/>;
            case 'assignment':
                return <UserOutlined className={'activity-icon-assignment'}/>;
            case 'priority':
                return <ExclamationOutlined className={'activity-icon-priority'}/>;
            case 'severity':
                return <InfoCircleOutlined className={'activity-icon-severity'}/>;
            case 'deadline':
                return <ScheduleOutlined className={'activity-icon-deadline'}/>;
            case 'solution':
                return <SolutionOutlined className={'activity-icon-solution'}/>;
            default:
                return <ClockCircleOutlined className={'activity-icon'}/>;
        }
    };

    const handleApiError = (error) => {
        console.error('API Error:', error);
        if (error.response?.status === 401) {
            message.error('Session expired. Please login again.');
        } else {
            message.error('Failed to load activity history.');
        }
    };

    return (
        <div className={'activity-stream-container'}>
            <div className={'activity-stream-list'}>
                <Timeline
                    loading={loading}
                    className={'activity-timeline'}
                    items={activities.map((activity) => ({
                        key: activity.id,
                        dot: getActivityIcon(activity.type),
                        children: (
                            <div className={'activity-item'}>
                                <div className={'activity-header'}>
                                    <p><strong>{activity.user}</strong></p>
                                    <p className={'activity-time'}>{activity.time}</p>
                                </div>
                                <div className={'activity-content'}>
                                    <p>{activity.action} <strong>{activity.value}</strong></p>
                                    {activity.oldValue && (
                                        <p className="activity-old-value">
                                            Previous value: {activity.oldValue}
                                        </p>
                                    )}
                                </div>
                            </div>
                        )
                    }))}
                />
            </div>
        </div>
    );
}

export default DefectActivityStream;