import React, { useState, useEffect, useRef } from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Table, Tabs, Card, message, Select, DatePicker, Input, Form, Space } from "antd";
import DefectComment from "./DefectComment";
import DefectActivityStream from "./DefectActivityStream";
import DefectSolution from "./DefectSolution";
import api from '../Ultils/Api';
import dayjs from 'dayjs';

const { Option } = Select;

function DefectRepositoryDetail() {
    const { host, repositoryName, defectId } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('defect_comment');
    const [defectDetail, setDefectDetail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [editedFields, setEditedFields] = useState({});
    const [users, setUsers] = useState([]);
    const [form] = Form.useForm();
    const activityStreamRef = useRef();

    const statusOptions = ['NEW', 'CLOSED', 'IN_PROGRESS', 'RESOLVED', 'REOPENED'];
    const priorityOptions = ['Low', 'Medium', 'High', 'Critical'];
    const severityOptions = ['Minor', 'Major', 'Critical', 'Blocker'];

    useEffect(() => {
        fetchDefectDetail();
        fetchUsers();
    }, [defectId, repositoryName]);

    const refreshActivityStream = () => {
        if (activityStreamRef.current?.fetchActivities) {
            activityStreamRef.current.fetchActivities();
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await api.get(`/member/${repositoryName}/members`);
            if (response.data) {
                const members = Object.values(response.data.members).map(member => ({
                    account: member.Account,
                    role: member.Role
                }));
                setUsers(members);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const fetchDefectDetail = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/defects/${defectId}`);
            const defect = response.data.defect;
            const deadlineDate = defect.deadline ? dayjs(defect.deadline) : null;

            const defectData = {
                key: defect.id,
                defect_title: defect.title,
                defect_reporter: defect.createdBy,
                defect_description: defect.description,
                defect_severity: defect.severity,
                defect_priority: defect.priority,
                defect_deadline: deadlineDate,
                defect_assigned_person: defect.assignedTo,
                defect_status: defect.status,
                defect_file_path: defect.filePath || '',
                defect_created_at: defect.createdAt,
                defect_updated_at: defect.updatedAt
            };

            setDefectDetail(defectData);
            setEditedFields(defectData);
            form.setFieldsValue({
                ...defectData,
                defect_deadline: deadlineDate
            });
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleApiError = (error) => {
        console.error('API Error:', error);
        if (error.response?.status === 401) {
            message.error('Session expired. Please login again.');
            navigate('/login');
        } else {
            message.error('Operation failed. Please try again.');
        }
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const handleFieldChange = (field, value) => {
        setEditedFields(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleStatusChange = async (status) => {
        try {
            await api.put(`/defects/${defectId}/status`, { status });
            message.success('Status updated successfully');
            await fetchDefectDetail();
            refreshActivityStream();
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleAssigneeChange = async (assignee) => {
        try {
            await api.put(`/defects/${defectId}/assign`, { assignee });
            message.success('Defect assigned successfully');
            await fetchDefectDetail();
            refreshActivityStream();
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleSaveChanges = async () => {
        try {
            const values = await form.validateFields();
            const updatedData = {
                severity: values.defect_severity,
                priority: values.defect_priority,
                filePath: values.defect_file_path,
                deadline: values.defect_deadline ? values.defect_deadline.format('YYYY-MM-DD HH:mm:ss') : null
            };

            await api.put(`/defects/${defectId}`, updatedData);
            message.success('Defect updated successfully');
            setDefectDetail({ ...defectDetail, ...values });
            setEditing(false);
            await fetchDefectDetail();
            refreshActivityStream();
        } catch (error) {
            if (error.errorFields) {
                message.error('Please check your input fields');
            } else {
                handleApiError(error);
            }
        }
    };

    const columns = [
        {
            title: 'Severity',
            dataIndex: 'defect_severity',
            key: 'defect_severity',
            render: (text) => editing ? (
                <Form.Item
                    name="defect_severity"
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: 'Severity is required' }]}
                >
                    <Select style={{ width: 130 }}>
                        {severityOptions.map(option => (
                            <Option key={option} value={option}>{option}</Option>
                        ))}
                    </Select>
                </Form.Item>
            ) : (text || '-')
        },
        {
            title: 'Priority',
            dataIndex: 'defect_priority',
            key: 'defect_priority',
            render: (text) => editing ? (
                <Form.Item
                    name="defect_priority"
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: 'Priority is required' }]}
                >
                    <Select style={{ width: 130 }}>
                        {priorityOptions.map(option => (
                            <Option key={option} value={option}>{option}</Option>
                        ))}
                    </Select>
                </Form.Item>
            ) : (text || '-')
        },
        {
            title: 'Status',
            dataIndex: 'defect_status',
            key: 'defect_status',
            render: (text) => (
                <Select
                    value={text}
                    onChange={handleStatusChange}
                    style={{ width: 130 }}
                >
                    {statusOptions.map(status => (
                        <Option key={status} value={status}>{status}</Option>
                    ))}
                </Select>
            )
        },
        {
            title: 'Deadline',
            dataIndex: 'defect_deadline',
            key: 'defect_deadline',
            render: (text, record) => editing ? (
                <Form.Item
                    name="defect_deadline"
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: 'Deadline is required' }]}
                >
                    <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                    />
                </Form.Item>
            ) : (record.defect_deadline ? record.defect_deadline.format('YYYY-MM-DD HH:mm:ss') : '-')
        },
        {
            title: 'Assigned Person',
            dataIndex: 'defect_assigned_person',
            key: 'defect_assigned_person',
            render: (text) => (
                <Select
                    value={text || undefined}
                    onChange={handleAssigneeChange}
                    style={{ width: 160 }}
                    placeholder="Select assignee"
                >
                    {users.map(user => (
                        <Option key={user.account} value={user.account}>{user.account}</Option>
                    ))}
                </Select>
            )
        }
    ];

    return (
        <LayoutComponentDetail>
            <div style={{ padding: '24px' }}>
                <div style={{ marginBottom: '30px',marginLeft:'5px' }}>
                    <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '8px' }}>
                        {defectDetail?.defect_title || 'Loading...'}
                    </h1>
                    <Space>
                        <span style={{ color: '#666' }}>Reporter: {defectDetail?.defect_reporter || '-'}</span>
                        <span style={{ color: '#666' }}>|</span>
                        <span style={{ color: '#666' }}>
                            Created: {defectDetail?.defect_created_at ? dayjs(defectDetail.defect_created_at).format('YYYY-MM-DD HH:mm:ss') : '-'}
                        </span>
                        <span style={{ color: '#666' }}>|</span>
                        <span style={{ color: '#666' }}>
                            Last Updated: {defectDetail?.defect_updated_at ? dayjs(defectDetail.defect_updated_at).format('YYYY-MM-DD HH:mm:ss') : '-'}
                        </span>
                    </Space>
                </div>

                <Form form={form} layout="vertical">
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                        <Card>
                            <div style={{ marginBottom: '16px' }}>
                                <h3 style={{ marginBottom: '8px' }}>Description</h3>
                                <p>{defectDetail?.defect_description || '-'}</p>
                            </div>

                            <div>
                                <h3 style={{ marginBottom: '8px' }}>File Path</h3>
                                {editing ? (
                                    <Form.Item
                                        name="defect_file_path"
                                        style={{ marginBottom: 0 }}
                                        rules={[{ required: true, message: 'File path is required' }]}
                                    >
                                        <Input
                                            placeholder="Enter file path"
                                            style={{ maxWidth: '500px' }}
                                        />
                                    </Form.Item>
                                ) : (
                                    <p>{defectDetail?.defect_file_path || '-'}</p>
                                )}
                            </div>
                        </Card>

                        <Table
                            columns={columns}
                            dataSource={defectDetail ? [defectDetail] : []}
                            loading={loading}
                            pagination={false}
                        />

                        <div style={{ display: 'flex', gap: '15px',marginLeft:'1120px' }}>
                            {editing ? (
                                <>
                                    <Button type="primary" onClick={handleSaveChanges} className={'custom-ok-button'}>
                                        Save
                                    </Button>
                                    <Button
                                        onClick={() => {
                                        setEditing(false);
                                        form.setFieldsValue(defectDetail);
                                    }}>
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <Button onClick={() => setEditing(true)} style={{width:'100%'}}>
                                    Edit Changes
                                </Button>
                            )}
                        </div>

                        <Tabs
                            activeKey={activeTab}
                            onChange={handleTabChange}
                            items={[
                                {
                                    key: 'defect_comment',
                                    label: 'Comment',
                                    children: <DefectComment
                                        defectId={defectId}
                                        repositoryName={repositoryName}
                                    />
                                },
                                {
                                    key: 'defect_activity_stream',
                                    label: 'Activity Stream',
                                    children: <DefectActivityStream
                                        ref={activityStreamRef}
                                        defectId={defectId}
                                        repositoryName={repositoryName}
                                    />
                                },
                                {
                                    key: 'defect_solution',
                                    label: 'Solution',
                                    children: <DefectSolution
                                        defectId={defectId}
                                        repositoryName={repositoryName}
                                    />
                                }
                            ]}
                        />
                    </div>
                </Form>
            </div>
        </LayoutComponentDetail>
    );
}

export default DefectRepositoryDetail;