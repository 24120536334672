// CollaborativeCursor.js
const CollaborativeCursor = ({ position, color, username }) => {
    return (
        <div
            style={{
                position: 'absolute',
                left: position.x,
                top: position.y,
                pointerEvents: 'none',
                zIndex: 1000,
                transition: 'all 0.1s ease'
            }}
        >
            <div style={{
                width: 0,
                height: 0,
                borderLeft: '8px solid transparent',
                borderRight: '8px solid transparent',
                borderBottom: `12px solid ${color || '#ff0000'}`,
                transform: 'rotate(-45deg)'
            }} />
            <div style={{
                background: color || '#ff0000',
                padding: '2px 6px',
                borderRadius: '3px',
                color: '#fff',
                fontSize: '12px',
                whiteSpace: 'nowrap'
            }}>
                {username || 'Anonymous'}
            </div>
        </div>
    );
};

export default CollaborativeCursor