import React, { useState, useEffect, useRef } from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { Card, message, Button, Modal, Input, Spin, Alert } from "antd";
import { EditOutlined, SaveOutlined, RollbackOutlined } from "@ant-design/icons";
import { EditorView, basicSetup } from "codemirror";
import { EditorState } from "@codemirror/state";
import { javascript } from "@codemirror/lang-javascript";
import { java } from "@codemirror/lang-java"
import { lineNumbers, keymap } from "@codemirror/view";
import { defaultKeymap } from "@codemirror/commands";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import ActiveUsersList from "../Shared/ActiveUserList";
import api from "../Ultils/Api";
import Cookies from "js-cookie";
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import CollaborativeCursor from "../Shared/CollaborativeCursor";
import { jwtDecode } from 'jwt-decode';

// Get username from token
const getUsernameFromToken = () => {
    try {
        const token = Cookies.get("token");
        if (!token) return "Anonymous";
        const decoded = jwtDecode(token);
        return decoded.sub || "Anonymous";
    } catch (error) {
        console.error('Error decoding token:', error);
        return "Anonymous";
    }
};


// Generate cursor color
const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = Math.abs(hash).toString(16).substring(0, 6);
    return `#${'0'.repeat(6 - color.length)}${color}`;
};

function ReviewCode() {
    // Router hooks
    const location = useLocation();
    const navigate = useNavigate();

    const {repositoryName } = useParams();

    // Refs
    const editorContainerRef = useRef(null);
    const editorViewRef = useRef(null);
    const stompClientRef = useRef(null);
    const lastChangeRef = useRef({ content: "", timestamp: 0 });
    const userIdRef = useRef(Math.random().toString(36).substring(7));
    const throttleTimeoutRef = useRef(null);

    // States
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditedContent] = useState("");
    const [originalContent, setOriginalContent] = useState("");
    const [commitMessage, setCommitMessage] = useState("");
    const [showCommitModal, setShowCommitModal] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [connected, setConnected] = useState(false);
    const [cursors, setCursors] = useState({});
    const [username, setUsername] = useState(getUsernameFromToken());
    const [savePermissionHolder, setSavePermissionHolder] = useState(null);
    const [activeUsers, setActiveUsers] = useState(new Map());

    // Get WebSocket channel ID
    const getFileChannel = () => {
        if (!fileData) return null;
        const { repositoryName, selectedBranch } = location.state;
        return `${repositoryName}_${selectedBranch}_${fileData.path}`.replace(/[^a-zA-Z0-9_]/g, '_');
    };

    // Initialize component
    useEffect(() => {
        const initializeComponent = async () => {
            try {
                if (!location.state?.fileData) {
                    throw new Error('No file data provided');
                }

                const { content, path, name } = location.state.fileData;
                if (!content || !path || !name) {
                    throw new Error('Incomplete file data');
                }

                setFileData(location.state.fileData);
                setEditedContent(content);
                setOriginalContent(content);
                setUsername(getUsernameFromToken());
            } catch (error) {
                message.error(error.message || 'Failed to load file data');
                navigate(-1);
            } finally {
                setIsLoading(false);
            }
        };

        initializeComponent();
    }, [location.state, navigate]);

    // Setup WebSocket and Editor
    useEffect(() => {
        if (!editorContainerRef.current || !fileData) return;

        const fileChannel = getFileChannel();
        if (!fileChannel) return;

        const socket = new SockJS('http://localhost:8080/ws');
        const stomp = Stomp.over(socket);
        stompClientRef.current = stomp;

        // Initialize Editor
        const view = new EditorView({
            state: EditorState.create({
                doc: editedContent,
                extensions: [
                    basicSetup,
                    javascript(),
                    java(),
                    lineNumbers(),
                    keymap.of(defaultKeymap),
                    EditorState.readOnly.of(!isEditing),
                    EditorView.updateListener.of((update) => {
                        if (update.docChanged && isEditing && stompClientRef.current?.connected) {
                            const newContent = update.state.doc.toString();

                            if (throttleTimeoutRef.current) {
                                clearTimeout(throttleTimeoutRef.current);
                            }

                            throttleTimeoutRef.current = setTimeout(() => {
                                const change = {
                                    content: newContent,
                                    timestamp: Date.now(),
                                    userId: userIdRef.current
                                };

                                if (newContent !== lastChangeRef.current.content) {
                                    lastChangeRef.current = change;
                                    stompClientRef.current.send(`/app/change/${fileChannel}`, {}, JSON.stringify(change));
                                    setEditedContent(newContent);
                                }
                            }, 50);
                        }
                    }),
                    EditorView.domEventHandlers({
                        mousemove(event, view) {
                            if (!stompClientRef.current?.connected || !isEditing) return;

                            const rect = view.dom.getBoundingClientRect();
                            const mouseData = {
                                userId: userIdRef.current,
                                username: username,
                                x: event.clientX - rect.left,
                                y: event.clientY - rect.top,
                                timestamp: Date.now()
                            };

                            // Chỉ gửi dữ liệu lên server, không cập nhật local cursors
                            stompClientRef.current.send(`/app/mouse/${fileChannel}`, {}, JSON.stringify(mouseData));
                        },
                        mouseleave(event, view) {
                            if (!stompClientRef.current?.connected || !isEditing) return;

                            // Chỉ thông báo cho server khi rời khỏi editor
                            stompClientRef.current.send(`/app/mouse/${fileChannel}`, {},
                                JSON.stringify({
                                    userId: userIdRef.current,
                                    username: username,
                                    x: -1,
                                    y: -1,
                                    timestamp: Date.now()
                                })
                            );
                        }
                    }),
                ]
            }),
            parent: editorContainerRef.current
        });

        editorViewRef.current = view;

        // Connect to WebSocket
        stomp.connect({}, () => {
            setConnected(true);

            // Join notification
            stomp.send(`/app/join/${fileChannel}`, {}, JSON.stringify({
                userId: userIdRef.current,
                username: username,
                timestamp: Date.now()
            }));

            // Subscribe to channels
            stomp.subscribe(`/topic/active-users/${fileChannel}`, message => {
                const userData = JSON.parse(message.body);
                if (userData.action === 'join') {
                    setActiveUsers(prev => new Map(prev).set(userData.userId, {
                        username: userData.username,
                        joinedAt: userData.timestamp
                    }));
                } else if (userData.action === 'leave') {
                    setActiveUsers(prev => {
                        const newMap = new Map(prev);
                        newMap.delete(userData.userId);
                        return newMap;
                    });

                    // Also remove cursor when user leaves
                    setCursors(prev => {
                        const newCursors = { ...prev };
                        delete newCursors[userData.userId];
                        return newCursors;
                    });
                }
            });

            stomp.subscribe(`/topic/save-permission/${fileChannel}`, message => {
                const permissionData = JSON.parse(message.body);
                setSavePermissionHolder(permissionData.holder);
            });

            // Trong phần subscribe nhận changes
            stomp.subscribe(`/topic/changes/${fileChannel}`, message => {
                const change = JSON.parse(message.body);
                if (!editorViewRef.current || !change.content || change.userId === userIdRef.current) return;

                if (change.timestamp > lastChangeRef.current.timestamp) {
                    lastChangeRef.current = change;
                    const view = editorViewRef.current;
                    const currentContent = view.state.doc.toString();

                    if (currentContent !== change.content) {
                        // Lưu lại position hiện tại của cursor và selection
                        const currentPos = view.state.selection.main.head;
                        const currentAnchor = view.state.selection.main.anchor;

                        view.dispatch({
                            changes: {
                                from: 0,
                                to: currentContent.length,
                                insert: change.content
                            },
                            // Khôi phục vị trí selection và cursor sau khi update
                            selection: {
                                anchor: currentAnchor,
                                head: currentPos
                            }
                        });
                        setEditedContent(change.content);
                    }
                }
            });

            stomp.subscribe(`/topic/mouse/${fileChannel}`, message => {
                const mouseData = JSON.parse(message.body);
                if (mouseData.x === -1 && mouseData.y === -1) {
                    // Remove cursor when user's mouse leaves editor
                    setCursors(prev => {
                        const newCursors = { ...prev };
                        delete newCursors[mouseData.userId];
                        return newCursors;
                    });
                } else {
                    // Update cursor position
                    setCursors(prev => ({
                        ...prev,
                        [mouseData.userId]: {
                            x: mouseData.x,
                            y: mouseData.y,
                            username: mouseData.username
                        }
                    }));
                }
            });
        });

        // Cleanup
        return () => {
            const fileChannel = getFileChannel();
            if (stompClientRef.current?.connected) {
                // Gửi leave message trước khi disconnect
                stompClientRef.current.send(`/app/leave/${fileChannel}`, {},
                    JSON.stringify({
                        userId: userIdRef.current,
                        username: username,
                        timestamp: Date.now()
                    })
                );
                stompClientRef.current.disconnect();
            }
        };
    }, [fileData, username]);

    // Update editor's readonly state
    useEffect(() => {
        const view = editorViewRef.current;
        if (!view) return;

        view.setState(EditorState.create({
            doc: view.state.doc,
            extensions: [
                basicSetup,
                javascript(),
                lineNumbers(),
                keymap.of(defaultKeymap),
                EditorState.readOnly.of(!isEditing),
                EditorView.updateListener.of((update) => {
                    if (update.docChanged && isEditing && stompClientRef.current?.connected) {
                        const newContent = update.state.doc.toString();

                        if (throttleTimeoutRef.current) {
                            clearTimeout(throttleTimeoutRef.current);
                        }

                        throttleTimeoutRef.current = setTimeout(() => {
                            const change = {
                                content: newContent,
                                timestamp: Date.now(),
                                userId: userIdRef.current
                            };

                            if (newContent !== lastChangeRef.current.content) {
                                lastChangeRef.current = change;
                                stompClientRef.current.send(`/app/change/${getFileChannel()}`, {}, JSON.stringify(change));
                                setEditedContent(newContent);
                            }
                        }, 50);
                    }
                }),
                EditorView.domEventHandlers({
                    mousemove(event, view) {
                        if (!stompClientRef.current?.connected) return;

                        const rect = view.dom.getBoundingClientRect();
                        const mouseData = {
                            userId: userIdRef.current,
                            username: username,
                            x: event.clientX - rect.left,
                            y: event.clientY - rect.top,
                            timestamp: Date.now()
                        };

                        setCursors(prev => ({
                            ...prev,
                            [userIdRef.current]: {
                                x: mouseData.x,
                                y: mouseData.y,
                                username: mouseData.username
                            }
                        }));

                        if (isEditing) {
                            stompClientRef.current.send(`/app/mouse/${getFileChannel()}`, {}, JSON.stringify(mouseData));
                        }
                    },
                    mouseleave(event, view) {
                        if (!stompClientRef.current?.connected) return;

                        setCursors(prev => {
                            const newCursors = { ...prev };
                            delete newCursors[userIdRef.current];
                            return newCursors;
                        });

                        if (isEditing) {
                            stompClientRef.current.send(`/app/mouse/${getFileChannel()}`, {},
                                JSON.stringify({
                                    userId: userIdRef.current,
                                    username: username,
                                    x: -1,
                                    y: -1,
                                    timestamp: Date.now()
                                })
                            );
                        }
                    }
                })
            ]
        }));
    }, [isEditing]);

    // Handlers
    const handleStartEditing = () => {
        setIsEditing(true);
        if (!savePermissionHolder) {
            const fileChannel = getFileChannel();
            stompClientRef.current?.send(`/app/save-permission/${fileChannel}`, {},
                JSON.stringify({ holder: username })
            );
        }
    };

    const handleSave = () => {
        if (savePermissionHolder !== username) {
            message.error(`Only ${savePermissionHolder} can save changes`);
            return;
        }

        if (!editedContent.trim()) {
            message.error('Code content cannot be empty');
            return;
        }
        setShowCommitModal(true);
    };

    const handleCommit = async () => {
        if (!commitMessage.trim()) {
            message.error('Please enter a commit message');
            return;
        }

        setIsSaving(true);
        try {
            const token = Cookies.get("token");
            const response = await api.put(`/git/repositories/${location.state.repositoryName}/files`, {
                branch: location.state.selectedBranch,
                filePath: fileData.path,
                content: editedContent,
                commitMessage: commitMessage
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.status === 200) {
                message.success('Changes saved successfully');
                setIsEditing(false);
                setShowCommitModal(false);
                setCommitMessage("");
                setOriginalContent(editedContent);

                const fileChannel = getFileChannel();
                stompClientRef.current?.send(`/app/save-permission/${fileChannel}`, {},
                    JSON.stringify({ holder: null })
                );

                navigate(`/repository/${location.state.repositoryName}/tree/${location.state.selectedBranch}`);
            }
        } catch (error) {
            message.error(error.response?.data || 'Failed to save changes');
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = () => {
        const fileChannel = getFileChannel();

        if (editedContent !== originalContent) {
            Modal.confirm({
                title: 'Discard changes?',
                content: 'All unsaved changes will be lost.',
                onOk: () => {
                    const view = editorViewRef.current;
                    if (view) {
                        view.dispatch({
                            changes: {
                                from: 0,
                                to: view.state.doc.length,
                                insert: originalContent
                            }
                        });
                    }

                    setEditedContent(originalContent);
                    setIsEditing(false);
                    setShowCommitModal(false);
                    setCommitMessage("");
                    setCursors({});

                    if (savePermissionHolder === username) {
                        stompClientRef.current?.send(`/app/save-permission/${fileChannel}`, {},
                            JSON.stringify({ holder: null })
                        );
                    }
                }
            });
        } else {
            setIsEditing(false);
            setCursors({});

            if (savePermissionHolder === username) {
                stompClientRef.current?.send(`/app/save-permission/${fileChannel}`, {},
                    JSON.stringify({ holder: null })
                );
            }
        }
    };

    if (isLoading) {
        return (
            <LayoutComponentDetail>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Spin size="large" />
                </div>
            </LayoutComponentDetail>
        );
    }

    return (
        <LayoutComponentDetail>
            <div className="flex flex-col">
                <div className="mb-4">
                    <ActiveUsersList
                        users={activeUsers}
                        savePermissionHolder={savePermissionHolder}
                    />
                </div>

                <Card title={`${location.state.repositoryName} / ${fileData?.name}`}>
                    <div style={{marginBottom: 16}}>
                        {savePermissionHolder && savePermissionHolder !== username && (
                            <Alert
                                message={`Only ${savePermissionHolder} can save changes`}
                                type="info"
                                showIcon
                                style={{marginBottom: 16}}
                            />
                        )}
                        {!isEditing ? (
                            <Button
                                type="primary"
                                icon={<EditOutlined/>}
                                onClick={handleStartEditing}
                                className={'custom-ok-button'}
                            >
                                Edit
                            </Button>
                        ) : (
                            <>
                                <Button
                                    icon={<RollbackOutlined/>}
                                    onClick={handleCancel}
                                    disabled={isSaving}
                                    style={{marginRight: 8}}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    icon={<SaveOutlined/>}
                                    onClick={handleSave}
                                    loading={isSaving}
                                    disabled={savePermissionHolder !== username}
                                >
                                    Save Changes
                                </Button>
                            </>
                        )}
                        {!connected && (
                            <span style={{marginLeft: 8, color: '#999'}}>
                                Connecting to collaboration server...
                            </span>
                        )}
                    </div>

                    <div
                        ref={editorContainerRef}
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '70vh',
                            cursor: isEditing ? 'text' : 'default'
                        }}
                        className="border border-gray-300"
                    >
                        {Object.entries(cursors).map(([id, data]) => (
                            <CollaborativeCursor
                                key={id}
                                id={id}
                                position={{x: data.x, y: data.y}}
                                color={stringToColor(id)}
                                username={data.username}
                            />
                        ))}
                    </div>

                    <Modal
                        title="Commit Changes"
                        open={showCommitModal}
                        onOk={handleCommit}
                        onCancel={() => setShowCommitModal(false)}
                        confirmLoading={isSaving}
                    >
                        <Input.TextArea
                            placeholder="Enter commit message..."
                            value={commitMessage}
                            onChange={(e) => setCommitMessage(e.target.value)}
                            rows={4}
                            disabled={isSaving}
                        />
                    </Modal>
                </Card>
            </div>
        </LayoutComponentDetail>
    );
}

export default ReviewCode;