import axios from 'axios';
import Cookies from 'js-cookie';


const api = axios.create({
    baseURL: 'https://dxgitauto.xyz/api/v1', // Replace with your API URL
});


api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
            // console.log(config.headers)
        }
        return config;

    },
    error => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle token expiration
// api.interceptors.response.use(
//     response => response,
//     async error => {
//         const originalRequest = error.config;
//
//         if (error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;
//             const refreshToken = Cookies.get('refreshToken');
//
//             if (refreshToken) {
//                 try {
//                     const response = await axios.post('http://localhost:8080/api/v1/auth/refresh', { token: refreshToken });
//                     const { token, tokenExpiry } = response.data;
//
//                     Cookies.set('token', token, { expires: new Date(tokenExpiry) });
//                     Cookies.set('tokenExpiry', tokenExpiry, { expires: new Date(tokenExpiry) });
//
//                     originalRequest.headers['Authorization'] = `Bearer ${token}`;
//                     return api(originalRequest);
//                 } catch (e) {
//                     console.error('Token refresh failed', e);
//                     logout();
//                 }
//             } else {
//                 logout();
//             }
//         }
//
//         return Promise.reject(error);
//     }
// );
axios.interceptors.response.use(
    response => {
        const statusCode = response.status;
        console.log(`Status Code: ${statusCode}`);
        return response;
    },
    error => {
        const statusCode = error.response.status;
        console.log(`Error Status Code: ${statusCode}`);
        return Promise.reject(error);
    }
);
export default api;
