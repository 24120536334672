import React, { useState } from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import {DatePicker, Form, Select, Tabs, Button} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
function UpdateDefect() {
    const [form] = Form.useForm();
    const [activeTab,setActiveTab] = useState('attachment');

    const fakeData = {
        statusDefect: 'in_progress',
        descriptionDefect: 'Description',
        severityDefect: 'high',
        priorityDefect: 'high',
        deadlineDefect: dayjs('2024-01-01 09:00:00'),
        assignmentDefect: 'quanNH'
    };
    const statusOptions = [
        {value:'open',label:'Open'},
        { value: 'in_progress', label: 'In Progress' },
        { value: 're-open', label: 'Re-Open' },
        { value: 'closed', label: 'Closed' },
        { value: 'rejected', label: 'Rejected' },
        { value: 'deferred', label: 'Deferred' },
        { value: 'duplicate', label: 'Duplicate' },
    ];
    const severityOptions = [
        { value: 'critical', label: 'Critical' },
        { value: 'high', label: 'High' },
        { value: 'medium', label: 'Medium' },
        { value: 'low', label: 'Low' }
    ];

    const priorityOptions = [
        { value: 'high', label: 'High' },
        { value: 'medium', label: 'Medium' },
        { value: 'low', label: 'Low' }
    ];

    const assignmentOptions = [
        { value: 'phongVD', label: 'PhongVD' },
        { value: 'quanNH', label: 'QuanNH' },
        { value: 'hungBV', label: 'HungBV' }
    ];
    const handleUpdateDefect = (values) => {

    }
    return (
        <LayoutComponentDetail>
            <div className="content-title">
                <h1>Update Defect</h1>
                <p>By Reporter</p>
            </div>
            <div className={'update-defect-container'}>
                <Form
                    form={form} layout={'vertical'}
                    className='update-form'
                    initialValues={fakeData}
                    onFinish={handleUpdateDefect}
                >
                    <Form.Item
                        name={'statusDefect'}
                        label={'Status'}
                    >
                        <Select
                            options={statusOptions}
                            style={{width: '100%'}}
                        />

                    </Form.Item>
                    <Form.Item
                        name="descriptionDefect"
                        label="Description"
                    >
                        <TextArea
                            rows={4}
                            placeholder="Enter description"
                        />
                    </Form.Item>
                    <div className="form-properties">
                        <Form.Item
                            name="severityDefect"
                            label="Severity"
                        >
                            <Select
                                options={severityOptions}
                            />
                        </Form.Item>

                        <Form.Item
                            name="priorityDefect"
                            label="Priority"
                        >
                            <Select
                                options={priorityOptions}
                            />
                        </Form.Item>

                        <Form.Item
                            name="deadlineDefect"
                            label="Deadline"
                        >
                            <DatePicker
                                showTime={{
                                    format: 'HH:mm',
                                    hourStep: 1,
                                    minuteStep: 1,
                                    showNow: false,
                                }}
                                format="YYYY-MM-DD HH:mm"
                                disabledDate={(current) => {
                                    return current && current < dayjs().startOf('day');
                                }}
                                className="deadline-picker"
                                onChange={(date, dateString) => {
                                    console.log('Selected:', dateString);
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="assignmentDefect"
                            label="Assignment"
                            rules={[{required: true, message: 'Please select assignee'}]}
                        >
                            <Select
                                options={assignmentOptions}
                                placeholder="Select assignment"
                            />
                        </Form.Item>
                    </div>
                    <Tabs
                        activeKey={activeTab}
                        onChange={setActiveTab}
                        className="update-tabs"
                        items={[
                            {
                                key: 'addSolution',
                                label: 'Add Solution',
                                children: (
                                    <div className="add-solution-area">
                                        Add solution
                                    </div>
                                )
                            }
                        ]}
                    />
                    <div className={'form-buttons'}>
                        <Button htmlType="submit" className={'custom-ok-button'}>Save</Button>
                        <Button onClick={() => form.resetFields()}>Cancel</Button>
                    </div>
                </Form>
            </div>

        </LayoutComponentDetail>
    );
}

export default UpdateDefect;