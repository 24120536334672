import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, message, Row, Select, Table, Input } from "antd";
import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import api from "../Ultils/Api";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";

const { Option } = Select;
const { Search } = Input;

function Commits() {
    const { host, repositoryName } = useParams();
    const navigate = useNavigate();
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [loading, setLoading] = useState(false);
    const [branchesLoading, setBranchesLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [list, setList] = useState([]);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortField, setSortField] = useState('time');

    const columns = [
        {
            title: 'Time',
            dataIndex: 'date',
            key: 'date',
            width: '200px',
            render: (text) => text ? new Date(text).toLocaleString() : '-'
        },
        {
            title: 'Actor',
            dataIndex: 'author',
            key: 'author',
            width: '150px',
            render: (text) => text || '-'
        },
        {
            title: 'Hash',
            dataIndex: 'short_hash',
            key: 'short_hash',
            width: '100px',
            render: (text) => text || '-'
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
            key: 'branch',
            width: '120px',
            render: (text) => text || '-'
        },
        {
            title: 'Commit message',
            dataIndex: 'message',
            key: 'message',
            ellipsis: true,
            render: (text) => (
                <span style={{ whiteSpace: 'pre-line' }}>{text || '-'}</span>
            )
        }
    ];

    const fetchBranches = async () => {
        setBranchesLoading(true);
        try {
            const response = await api.get(`/git/repositories/${repositoryName}/branches`);

            if (response.data && response.data[repositoryName]) {
                const branchList = response.data[repositoryName].map(branch => branch.name);
                setBranches(branchList);

                if (!selectedBranch) {
                    const defaultBranch = branchList.find(b => b === 'main' || b === 'master') || branchList[0];
                    setSelectedBranch(defaultBranch);
                }
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setBranchesLoading(false);
        }
    };

    const fetchCommitData = async () => {
        if (!selectedBranch) return;

        setLoading(true);
        try {
            const url = `/git/repositories/${repositoryName}/commits?branch=${encodeURIComponent(selectedBranch)}`;
            const response = await api.get(url);

            let commitData;
            if (response.data && response.data.commits) {
                commitData = response.data.commits;
            } else if (Array.isArray(response.data)) {
                commitData = response.data;
            } else {
                setList([]);
                return;
            }

            const processedCommits = commitData.map((commit, index) => ({
                key: commit.hash || String(index),
                hash: commit.hash,
                short_hash: commit.short_hash,
                author: commit.author ? commit.author.replace(/[^\x20-\x7E]/g, '') : '',
                date: commit.date,
                message: commit.message,
                branch: commit.branch,
                current_branch: commit.current_branch
            }));

            setList(processedCommits);
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleApiError = (error) => {
        console.error('API Error:', {
            message: error.message,
            status: error.response?.status,
            data: error.response?.data
        });

        if (error.response?.status === 401) {
            message.error('Session expired. Please login again.');
            navigate('/login');
        } else {
            message.error('Failed to fetch commits. Please try again.');
        }
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const handleSortChange = (value) => {
        setSortField(value);
    };

    const toggleSortOrder = () => {
        setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    };

    const getFilteredAndSortedData = () => {
        const filteredData = list.filter(commit => {
            const searchLower = searchText.toLowerCase();
            return (
                (commit.message && commit.message.toLowerCase().includes(searchLower)) ||
                (commit.author && commit.author.toLowerCase().includes(searchLower)) ||
                (commit.hash && commit.hash.toLowerCase().includes(searchLower)) ||
                (commit.branch && commit.branch.toLowerCase().includes(searchLower))
            );
        });
        return filteredData.sort((a, b) => {
            let comparison = 0;

            switch (sortField) {
                case 'time':
                    comparison = new Date(a.date) - new Date(b.date);
                    break;
                case 'actor':
                    comparison = (a.author || '').localeCompare(b.author || '');
                    break;
                case 'branch':
                    comparison = (a.branch || '').localeCompare(b.branch || '');
                    break;
                case 'commitMessage':
                    comparison = (a.message || '').localeCompare(b.message || '');
                    break;
                default:
                    comparison = new Date(a.date) - new Date(b.date);
            }

            return sortOrder === 'asc' ? comparison : -comparison;
        });
    };

    useEffect(() => {
        if (repositoryName) {
            fetchBranches();
        }
    }, [repositoryName]);

    useEffect(() => {
        if (selectedBranch) {
            fetchCommitData();
        }
    }, [selectedBranch]);

    const sortOptions = [
        { value: 'time', label: 'Time' },
        { value: 'actor', label: 'Actor' },
        { value: 'branch', label: 'Branch' },
        { value: 'commitMessage', label: 'Commit Message' }
    ];

    return (
        <LayoutComponentDetail>
            <div className="content-title">
                <h1>Commits of the repository {repositoryName}</h1>
            </div>
            <div className="commit-action">
                <Row style={{ marginBottom: '20px' }}>
                    <Col span={8}>
                        <Select
                            value={selectedBranch}
                            style={{ width: '110px', marginLeft: '20px' }}
                            onChange={(value) => setSelectedBranch(value)}
                            loading={branchesLoading}
                        >
                            {branches.map((branch) => (
                                <Option key={branch} value={branch}>{branch}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={10}>
                        <Search
                            placeholder="Search commits..."
                            className="search"
                            allowClear
                            onSearch={handleSearch}
                            style={{ borderRadius: '25px', height: '40px' }}
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            value={sortField}
                            className="select-sort"
                            style={{ width: '93%' }}
                            onChange={handleSortChange}
                        >
                            {sortOptions.map(option => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={2}>
                        <Button
                            type="text"
                            icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                            onClick={toggleSortOrder}
                            className="sort-btn"
                            style={{ width: '80%' }}
                        />
                    </Col>
                </Row>
            </div>
            <div className="list-commits">
                <Table
                    columns={columns}
                    dataSource={getFilteredAndSortedData()}
                    loading={loading}
                    pagination={{
                        pageSize: 50,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showTotal: (total) => `Total ${total} commits`
                    }}
                    scroll={{ x: 1200 }}
                />
            </div>
        </LayoutComponentDetail>
    );
}

export default Commits;