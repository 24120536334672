import React, { useEffect, useState } from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import { useNavigate, useParams } from "react-router-dom";
import {Button, Col, Form, message, Modal, Row, Select, Table} from "antd";
import Input from "antd/es/input/Input";
import {DeleteOutlined, SortAscendingOutlined, SortDescendingOutlined} from "@ant-design/icons";
import api from "../Ultils/Api"
import Search from "antd/es/input/Search";

const {Option} = Select;
function Branches() {
    const [branches, setBranches] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortField, setSortField] = useState('branch_name');
    const [isModalVisibleCreate, setIsModalVisibleCreate] = useState(false);
    const [isModalVisibleDelete, setIsModalVisibleDelete] = useState(false);
    const [newBranchName, setNewBranchName] = useState('');
    const [createFrom, setCreateFrom] = useState('');
    const [branchToDelete, setBranchToDelete] = useState(null);
    const { host, repositoryName } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (repositoryName) {
            fetchRepositoryData();
        }
    }, [repositoryName]);

    const fetchRepositoryData = async () => {
        setLoading(true);
        try {
            const branchResponse = await api.get(`/git/repositories/${repositoryName}/branches`);
            const branchData = branchResponse.data;

            if (branchData && branchData[repositoryName]) {
                const formattedBranches = branchData[repositoryName].map((branch, index) => ({
                    key: index,
                    branch_name: branch.name,
                    branch_last_commit: branch.lastCommit,
                    branch_last_updated: branch.lastUpdate,
                    default: branch.default,
                    protected: branch.protected
                }));
                setBranches(formattedBranches);
                const defaultBranch = formattedBranches.find(b => b.default) || formattedBranches[0];
                setCreateFrom(defaultBranch.branch_name);
            } else {
                message.warning('Branch data structure is unexpected');
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const handleSortChange = (value) => {
        setSortField(value);
    };

    const toggleSortOrder = () => {
        setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    };

    const getFilteredAndSortedData = () => {
        return branches
            .filter(branch => {
                const searchLower = searchText.toLowerCase();
                return (
                    (branch.branch_name && branch.branch_name.toLowerCase().includes(searchLower)) ||
                    (branch.branch_last_commit && branch.branch_last_commit.toLowerCase().includes(searchLower))
                );
            })
            .sort((a, b) => {
                let comparison = 0;
                if (sortField === 'branch_name' || sortField === 'branch_last_commit') {
                    comparison = (a[sortField] || '').localeCompare(b[sortField] || '');
                } else if (sortField === 'branch_last_updated') {
                    const dateA = new Date(a[sortField] || 0);
                    const dateB = new Date(b[sortField] || 0);
                    comparison = dateA - dateB;
                }
                return sortOrder === 'asc' ? comparison : -comparison;
            });
    };

    const sortOptions = [
        { value: 'branch_name', label: 'Name' },
        { value: 'branch_last_commit', label: 'Last Commit' },
        { value: 'branch_last_updated', label: 'Last Updated' }
    ];

    const handleCreateBranch = async (e) => {
        e.preventDefault();
        if (newBranchName.trim() === '') {
            message.error('Please enter a valid branch name');
            return;
        }

        try {
            await api.post(`/git/repositories/${repositoryName}/branches`, {
                sourceBranch: createFrom,
                newBranch: newBranchName
            });

            message.success('Branch created successfully');
            setIsModalVisibleCreate(false);
            setNewBranchName('');
            fetchRepositoryData();
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleDeleteBranch = async () => {
        if (branchToDelete) {
            try {
                await api.delete(`/git/repositories/${repositoryName}/branches/${branchToDelete.branch_name}`);

                message.success('Branch deleted successfully');
                setIsModalVisibleDelete(false);
                setBranchToDelete(null);
                fetchRepositoryData();
            } catch (error) {
                handleApiError(error);
            }
        }
    };

    const handleApiError = (error) => {
        console.error('API Error:', error);
        if (error.response?.status === 401) {
            message.error('Session expired. Please login again.');
            navigate('/login');
        } else {
            message.error(error.response?.data || 'Operation failed. Please try again.');
        }
    };

    const columns = [
        { title: 'Name', dataIndex: 'branch_name', key: 'branch_name' },
        { title: 'Last Commit', dataIndex: 'branch_last_commit', key: 'branch_last_commit' },
        { title: 'Last Updated', dataIndex: 'branch_last_updated', key: 'branch_last_updated' },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                !record.protected && <Button onClick={() => handleShowDeleteBranchModal(record)}><DeleteOutlined /></Button>
            )
        },
    ];

    const handleShowCreateBranchModal = () => setIsModalVisibleCreate(true);
    const handleCancelCreateBranchModal = () => {
        setIsModalVisibleCreate(false);
        setNewBranchName('');
    };

    const handleShowDeleteBranchModal = (branch) => {
        setBranchToDelete(branch);
        setIsModalVisibleDelete(true);
    };

    const handleCancelDeleteBranchModal = () => {
        setBranchToDelete(null);
        setIsModalVisibleDelete(false);
    };

    return (
        <LayoutComponentDetail>
            <div className="content-title">
                <h1>Branches of the repository {repositoryName}</h1>
            </div>
            <Row gutter={16} className="search-sort-container">
                <Col span={18}>
                    <Search
                        placeholder="Search"
                        className="search"
                        allowClear
                        onSearch={handleSearch}
                        style={{ borderRadius: '25px', height: '40px', marginLeft: '5px' }}
                    />
                </Col>
                <Col span={4}>
                    <Select
                        value={sortField}
                        className="select-sort"
                        style={{ width: '100%' }}
                        onChange={handleSortChange}
                    >
                        {sortOptions.map(option => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={2}>
                    <Button
                        type="text"
                        icon={sortOrder ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                        onClick={toggleSortOrder}
                        className="sort-btn"
                        style={{ width: '78%' }}
                    />
                </Col>
            </Row>
            <div className="btn-create-branch">
                <Button onClick={handleShowCreateBranchModal}>Create Branch</Button>
                <Modal
                    title="Create Branch"
                    visible={isModalVisibleCreate}
                    onOk={handleCreateBranch}
                    onCancel={handleCancelCreateBranchModal}
                    okText="Create"
                    cancelText="Cancel"
                    okButtonProps={{ className: 'custom-ok-button' }}
                >
                    <Form layout="vertical">
                        <Form.Item
                            label="Branch Name:"
                            name="branchName"
                            rules={[
                                { required: true, message: 'Please input branch name' },
                                { max: 30, message: 'Branch name cannot be more than 30 characters long' }
                            ]}
                            validateTrigger="onBlur"
                        >
                            <Input
                                type="text"
                                placeholder="Branch Name"
                                value={newBranchName}
                                onChange={(e) => setNewBranchName(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Create From:"
                            name="createFrom"
                            validateTrigger="onBlur"
                        >
                            <Select value={createFrom} onChange={(value) => setCreateFrom(value)}>
                                {branches.map(branch => (
                                    <Select.Option key={branch.key} value={branch.branch_name}>
                                        {branch.branch_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
            <div className="branch-container">
                <div className="branch-list">
                    <Table
                        columns={columns}
                        dataSource={getFilteredAndSortedData()}
                        loading={loading}
                    />
                </div>
                <Modal
                    title="Delete branch. Are you ABSOLUTELY SURE?"
                    visible={isModalVisibleDelete}
                    onOk={handleDeleteBranch}
                    onCancel={handleCancelDeleteBranchModal}
                    okText="Delete"
                    cancelText="Cancel"
                    okButtonProps={{ className: 'custom-ok-button' }}
                >
                    {branchToDelete && (
                        <p>Are you sure you want to delete <strong>{branchToDelete.branch_name}</strong>?</p>
                    )}
                </Modal>
            </div>
        </LayoutComponentDetail>
    );
}

export default Branches;