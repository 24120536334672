import React, { useState, useEffect } from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, message, Modal, Select, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Input from "antd/es/input/Input";
import api from '../Ultils/Api';
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const { Option } = Select;

//Constants for role display mapping
const ROLE_DISPLAY = {
    'dev': 'Developer',
    'guest': 'Guest',
    'tester': 'Tester'
};

// Constants for valid role values
const VALID_ROLES = ['dev', 'guest', 'tester'];

function Members() {
    // State for data
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');

    // Modal visibility states
    const [isAddMemberModalVisible, setIsAddMemberModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isViewRoleModalVisible, setIsViewRoleModalVisible] = useState(false);

    // Form states
    const [memberForm, setMemberForm] = useState({
        username: '',
        role: 'guest'
    });
    const [memberToDelete, setMemberToDelete] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);

    // Router hooks
    const { repositoryName } = useParams();
    const navigate = useNavigate();

    // Check if current user is owner
    const isCurrentUserOwner = () => {
        return members.some(member =>
            member.member_account === username &&
            member.member_role === 'Owner'
        );
    };

    // Check if member is owner
    const isMemberOwner = (memberRole) => {
        return memberRole === 'Owner';
    };

    // Effects
    useEffect(() => {
        const token = Cookies.get("token");
        if (token) {
            const decoded = jwtDecode(token);
            setUsername(decoded.sub);
        }
    }, []);

    useEffect(() => {
        if (repositoryName) {
            fetchMembers();
        }
    }, [repositoryName]);

    // Table columns configuration
    const columns = [
        {
            title: 'Account',
            dataIndex: 'member_account',
            key: 'member_account'
        },
        {
            title: 'Source',
            dataIndex: 'member_source',
            key: 'member_source'
        },
        {
            title: 'Role',
            dataIndex: 'member_role',
            key: 'member_role',
            render: (role, record) => (
                <span
                    className="role-link"
                    onClick={() => handleRoleClick(record)}
                    style={{ color: isCurrentUserOwner() ? 'blue' : 'inherit', cursor: isCurrentUserOwner() ? 'pointer' : 'default' }}
                >
                    {ROLE_DISPLAY[role.toLowerCase()] || role}
                </span>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                // Don't show delete button for owners or current user
                if (record.member_role === 'Owner' || record.member_account === username) {
                    return null;
                }
                // Show delete button only if current user is owner
                return isCurrentUserOwner() ? (
                    <Button
                        onClick={() => showDeleteModal(record)}
                        icon={<DeleteOutlined />}
                    />
                ) : null;
            }
        }
    ];

    // API Functions
    const fetchMembers = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/member/${repositoryName}/members`);

            if (response.data && response.data.members) {
                const processedMembers = response.data.members.map((member, index) => ({
                    key: index,
                    member_account: member.Account,
                    member_source: 'Direct member',
                    member_role: member.Role
                }));
                setMembers(processedMembers);
            } else {
                setMembers([]);
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
        }
    };

    const addMember = async () => {
        if (!memberForm.username.trim()) {
            message.error('Please enter a valid username');
            return;
        }

        try {
            const response = await api.post(`/member/${repositoryName}/members`, {
                member: memberForm.username,
                role: memberForm.role
            });

            if (response.data) {
                message.success('Member added successfully');
                closeAddModal();
                fetchMembers();
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    // Helper function to convert role names
    const convertRoleValue = (role) => {
        // Convert from UI display format to API value
        if (role.toLowerCase() === 'developer') return 'dev';
        if (role.toLowerCase() === 'dev') return 'dev';
        return role.toLowerCase();
    };

    const updateMemberRole = async () => {
        if (!selectedMember || !selectedRole) return;

        try {
            await api.post('/git/change-role', {
                member: selectedMember.member_account,
                repositoryName: repositoryName,
                oldRoleName: convertRoleValue(selectedMember.member_role),
                newRoleName: selectedRole
            });

            message.success('Role updated successfully');
            setIsViewRoleModalVisible(false);
            fetchMembers();
        } catch (error) {
            handleApiError(error);
        }
    };

    const deleteMember = async () => {
        if (!memberToDelete) return;

        if (!isCurrentUserOwner()) {
            message.error('Only repository owner can delete members');
            return;
        }

        try {
            await api.delete(`/member/${repositoryName}/members/${memberToDelete.member_account}`);
            message.success('Member deleted successfully');
            closeDeleteModal();
            fetchMembers();
        } catch (error) {
            handleApiError(error);
        }
    };

    // Error Handler
    const handleApiError = (error) => {
        console.error('API Error:', error);
        if (error.response?.status === 401) {
            message.error('Session expired. Please login again.');
            navigate('/login');
        } else if (error.response?.status === 403) {
            message.error('Permission denied.');
        } else {
            message.error(error.response?.data?.message || 'Operation failed');
        }
    };

    // Navigation Handler
    const handleNavigation = (value) => {
        if (repositoryName) {
            navigate(`/repository/${repositoryName}/${value}`);
        }
    };

    // Modal Handlers
    const showAddModal = () => {
        if (!isCurrentUserOwner()) {
            message.error('Only repository owner can add members');
            return;
        }
        setIsAddMemberModalVisible(true);
    };

    const closeAddModal = () => {
        setIsAddMemberModalVisible(false);
        setMemberForm({ username: '', role: 'Guest' });
    };

    const showDeleteModal = (member) => {
        setMemberToDelete(member);
        setIsDeleteModalVisible(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalVisible(false);
        setMemberToDelete(null);
    };

    const handleRoleClick = (member) => {
        // Only allow role editing if current user is owner
        if (!isCurrentUserOwner()) {
            return;
        }
        setSelectedMember(member);
        setSelectedRole(member.member_role);
        setIsViewRoleModalVisible(true);
    };

    return (
        <LayoutComponentDetail>
            <div className="content-title">
                <h1>Members Of Repository {repositoryName}</h1>
            </div>

            <div className="member-container">
                {/* Action Bar */}
                <div className="member-action" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}>
                    <Select
                        defaultValue="members"
                        onChange={handleNavigation}
                        style={{ width: '160px' }}
                    >
                        <Option value="members">List Member</Option>
                        <Option value="activity">Activity</Option>
                        <Option value="contribute">Contribute Chart</Option>
                    </Select>

                    {isCurrentUserOwner() && (
                        <Button onClick={showAddModal} type="primary">
                            Add Member
                        </Button>
                    )}
                </div>

                {/* Members Table */}
                <div className="member-list">
                    <Table
                        columns={columns}
                        dataSource={members}
                        loading={loading}
                    />
                </div>

                {/* Add Member Modal */}
                <Modal
                    title="Add Member"
                    open={isAddMemberModalVisible}
                    onOk={addMember}
                    onCancel={closeAddModal}
                    okText="Add"
                    cancelText="Cancel"
                >
                    <Form layout="vertical">
                        <Form.Item
                            label="Username:"
                            required
                            rules={[{ required: true, message: 'Please input member name' }]}
                        >
                            <Input
                                placeholder="Username"
                                value={memberForm.username}
                                onChange={(e) => setMemberForm(prev => ({ ...prev, username: e.target.value }))}
                            />
                        </Form.Item>
                        <Form.Item label="Role:">
                            <Select
                                value={memberForm.role}
                                onChange={(value) => setMemberForm(prev => ({ ...prev, role: value }))}
                            >
                                <Option value="guest">{ROLE_DISPLAY['guest']}</Option>
                                <Option value="dev">{ROLE_DISPLAY['dev']}</Option>
                                <Option value="tester">{ROLE_DISPLAY['tester']}</Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* Delete Confirmation Modal */}
                <Modal
                    title="Confirm Delete"
                    open={isDeleteModalVisible}
                    onOk={deleteMember}
                    onCancel={closeDeleteModal}
                    okText="Delete"
                    cancelText="Cancel"
                    okButtonProps={{ danger: true }}
                >
                    {memberToDelete && (
                        <p>Are you sure you want to delete member <strong>{memberToDelete.member_account}</strong>?</p>
                    )}
                </Modal>

                {/* View Role Modal */}
                {/* Role Details Modal - Only shown for owners */}
                {isCurrentUserOwner() && (
                    <Modal
                        title="Role Details"
                        open={isViewRoleModalVisible}
                        onCancel={() => setIsViewRoleModalVisible(false)}
                        footer={[
                            <Button key="close" onClick={() => setIsViewRoleModalVisible(false)}>
                                Close
                            </Button>,
                            !isMemberOwner(selectedMember?.member_role) && (
                                <Button
                                    key="change"
                                    type="primary"
                                    onClick={updateMemberRole}
                                >
                                    Change
                                </Button>
                            )
                        ]}
                    >
                        <Form layout="vertical">
                            <Form.Item label="Member:">
                                <div>{selectedMember?.member_account || 'No member selected'}</div>
                            </Form.Item>
                            <Form.Item label="Role:">
                                {isMemberOwner(selectedMember?.member_role) ? (
                                    <div>{selectedMember?.member_role}</div>
                                ) : (
                                    <Select
                                        value={selectedRole}
                                        onChange={setSelectedRole}
                                    >
                                        <Option value="guest">{ROLE_DISPLAY['guest']}</Option>
                                        <Option value="dev">{ROLE_DISPLAY['dev']}</Option>
                                        <Option value="tester">{ROLE_DISPLAY['tester']}</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Form>
                    </Modal>
                )}
            </div>
        </LayoutComponentDetail>
    );
}

export default Members;