import React, {useState} from "react";
import {Button, Card, Form} from "antd";
import Input from "antd/es/input/Input";
import { useNavigate} from "react-router-dom";
import {useForm} from "antd/es/form/Form";
import {Eye, EyeOff, Lock, Mail} from "lucide-react";
function ForgotPassword() {
    const [loading,setLoading] = useState(false);
    const [currentStep,setCurrentStep] = useState('email');
    const [showPassword,setShowPassword] = useState('');
    const [userEmail,setUserEmail] = useState('');
    const [isOTPSent,setIsOTPSent] = useState(false);
    const {form} = useForm();
    const navigate = useNavigate();

    const handleSendClick = (values) => {
        setLoading(true);
        setTimeout(()=> {
            setLoading(false);
            setCurrentStep('otp');
            setUserEmail(values.email);
        },1500)
    };
    const handleOTPSubmit = (values) => {
        setLoading(true);
        setTimeout(()=> {
            setLoading(false);
            setCurrentStep('reset');
        },1500)
    };
    const handleResetPassword = (values) => {
        setLoading(true);
        setTimeout(()=> {
            setLoading(false);
            navigate('/login');
        },1500)
    }
    const handleResendOTP = () => {
        setLoading(true);
        setTimeout(()=> {
            setLoading(false);
        },1500)
    };
    const renderEmailCard  = () => (
        <Card className={'forgot-password-card'}>
            <h1 className={'forgot-password-title'}>Forgot Password</h1>
            <Form
                form={form}
                name={'forgotPassword'}
                layout={'vertical'}
                onFinish={handleSendClick}
            >
                <Form.Item
                    label={'Email'}
                    name={'email'}
                    rules={[
                        {required: true, message: 'Please input your email'},
                        {type: 'email', message: 'Please enter a valid email'}
                    ]}
                    validateTrigger={'onBlur'}
                >
                    <Input
                        prefix={<Mail size={18}/>}
                        placeholder={'Enter your email'}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType={'submit'}
                        loading={loading}
                        className={'login-button'}
                    >
                        Send
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );

    const renderOTPCard = () => (
        <Card className={'forgot-password-card'}>
            <h1 className={'forgot-password-title'}>Enter Verification Code</h1>
            <p className={'otp-description'} style={{marginBottom:'25px'}}>
                We have sent a verification code to <strong>{userEmail}</strong>
            </p>

            <Form
                name={'otpVerification'}
                layout={'vertical'}
                onFinish={handleOTPSubmit}
            >
                <Form.Item
                    name={'otp'}
                    rules={[
                        {required: true, message: 'Please input verification code'},
                        {len: 6, message: 'Please enter 6 digits code'}
                    ]}
                >
                    <Input
                        maxLength={6}
                        className={'otp-input'}
                        placeholder="Enter 6 digits code"
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        className={'verify-button'}
                    >
                        Verify
                    </Button>
                </Form.Item>
            </Form>

            <div className={'otp-actions'}>
                <Button
                    type="link"
                    onClick={() => setIsOTPSent(false)}
                    className={'back-button'}
                >
                    Change Email
                </Button>
                <Button
                    type="link"
                    onClick={handleResendOTP}
                    loading={loading}
                    className={'resend-button'}
                >
                    Resend Code
                </Button>
            </div>
        </Card>
    )
    const renderResetPasswordCard = () => (
        <Card className={'forgot-password-card'}>
            <h1 className={'forgot-password-title'}>Reset Password</h1>
            <Form
                name={'resetPassword'}
                layout={'vertical'}
                onFinish={handleResetPassword}
            >
                <Form.Item
                    label={'New Password'}
                    name={'password'}
                    rules={[
                        {required: true, message: 'Please input new password'},
                        {min: 8, message: 'Password must be at least 8 characters'}
                    ]}
                >
                    <Input.Password
                        prefix={<Lock size={18}/>}
                        placeholder={'Enter new password'}
                        iconRender={(visible) =>
                            visible ? <Eye size={18} /> : <EyeOff size={18} />
                        }
                    />
                </Form.Item>

                <Form.Item
                    label={'Confirm Password'}
                    name={'confirmPassword'}
                    dependencies={['password']}
                    rules={[
                        {required: true, message: 'Please confirm your password'},
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords do not match');
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        prefix={<Lock  size={18}/>}
                        placeholder={'Confirm new password'}
                        iconRender={(visible) =>
                            visible ? <Eye size={18} /> : <EyeOff size={18} />
                        }
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        className={'verify-button'}
                    >
                        Reset Password
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )
    return (
        <>
            <div className={'forgot-password-container'}>
                {currentStep === 'email' && renderEmailCard()}
                {currentStep === 'otp' && renderOTPCard()}
                {currentStep === 'reset' && renderResetPasswordCard()}
            </div>
        </>
    );
}

export default ForgotPassword