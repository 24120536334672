import React, {useState} from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, Select, Statistic} from "antd";
import {BranchesOutlined} from "@ant-design/icons";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis,Tooltip} from "recharts";
function ContributeChartProject() {
    const { repositoryName } = useParams();
    const { Option } = Select;
    const navigate = useNavigate();
    const [selectedBranch,setSelectedBranch] = useState('');
    const branchList = [
        {id:'1',name:'PhongVD'},
        {id:'2',name:'DucVD'},
    ]
    const commitData = [
        { month: 'Jan', commits: 0 },
        { month: 'Feb', commits: 0 },
        { month: 'Mar', commits: 0 },
        { month: 'Apr', commits: 0 },
        { month: 'May', commits: 0 },
        { month: 'Jun', commits: 20 },
        { month: 'Jul', commits: 12 },
        { month: 'Aug', commits: 35 },
        { month: 'Sep', commits: 35 },
        { month: 'Oct', commits: 21 },
        { month: 'Nov', commits: 14 },
        { month: 'Dec', commits: 81 }
    ];


    const ToolHover = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{
                    backgroundColor: 'white',
                    padding: '8px 12px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                    <p style={{ margin: 0 }}>{`Month: ${label}`}</p>
                    <p style={{ margin: '4px 0 0 0', fontWeight: 500 }}>
                        {`Commits: ${payload[0].value}`}
                    </p>
                </div>
            );
        }
        return null;
    };
    const handleSelectChange = (value) => {
        if (!repositoryName) {
            console.error('Repository name is undefined');
            return;
        }

        const basePath = `/repository/${repositoryName}`;
        switch (value) {
            case 'activityChart':
                navigate(`${basePath}/activity`);
                break;
            case 'contributeChart':
                navigate(`${basePath}/contribute`);
                break;
            case 'listMember':
                navigate(`${basePath}/member`);
                break;
            default:
                break;
        }
    }
    const handleHistory = () => {

    }

    return (
        <>
            <LayoutComponentDetail>
                <div className={'content-title'}>
                    <h1>Contribute Chart </h1>
                </div>
                <div className={'contribute-chart-project-container'}>
                    <div className={'contribute-chart-action'}>
                        <Select defaultValue={'contributeChart'} onChange={handleSelectChange} style={{width: '160px'}}>
                            <Option value={'listMember'}>List Member</Option>
                            <Option value={'activityChart'}>Activity</Option>
                            <Option value={'contributeChart'}>Contribute Chart</Option>
                        </Select>
                        <Select
                            placeholder={'Select Developer'}
                            style={{width:'200px'}}
                            onChange={value => setSelectedBranch(value)}
                            defaultValue={'all'}
                            className={'select-branch'}
                        >
                            <Option value={'all'}>All Members</Option>
                            {branchList.map(branch=> (
                                <Option key={branch.id} value={branch.id}>
                                    {branch.name}
                                </Option>
                            ))}
                        </Select>
                        <Button onClick={handleHistory} className={'btn-history'}>History</Button>
                    </div>
                    <div className={'contribute-chart-card-information'}>
                        <Card>
                            <Statistic
                                title={'Total Commits'}
                                value={'100'}
                                prefix={<BranchesOutlined/>}
                            />
                        </Card>
                    </div>
                    <div className={'contribute-chart-project-graph'}>
                        <Card style={{ marginTop: '24px' }}>
                            <h3>Commits to PhongVD</h3>
                            <div style={{ height: '400px', width: '100%', marginTop: '20px' }}>
                                <ResponsiveContainer>
                                    <BarChart
                                        data={commitData}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                        <XAxis
                                            dataK   ey="month"
                                            axisLine={false}
                                        />
                                        <YAxis
                                            domain={[0, 11]}
                                            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90,100]}
                                            axisLine={false}
                                        />
                                        <Tooltip content={<ToolHover />} />
                                        <Bar
                                            dataKey="commits"
                                            fill="#f84502"
                                            radius={[2, 2, 0, 0]}
                                            maxBarSize={50}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </Card>
                    </div>
                </div>
            </LayoutComponentDetail>
        </>
    )
}

export default ContributeChartProject;