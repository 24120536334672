import React, {useEffect, useState} from "react";
import {Flex, Menu} from "antd";
import {Link, useParams} from "react-router-dom";
import logo from "../Assets/image/logo.jpg"
import {
    AppstoreAddOutlined,
    BranchesOutlined,
    BugOutlined,
    FolderOutlined, HistoryOutlined,
    TeamOutlined, UserOutlined
} from "@ant-design/icons";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";

function SiderBarDetail() {
    const { repositoryName } = useParams();
    const [isRootUser, setIsRootUser] = useState(false);
    useEffect(() => {
        const token = Cookies.get("token");
        if (token) {
            try {
                const decoded = jwtDecode(token);
                // Check if username is 'root'
                setIsRootUser(decoded.sub === 'root');
            } catch (error) {
                console.error('Error decoding token:', error);
                setIsRootUser(false);
            }
        }
    }, []);
    return(
        <>
            <Flex align={'center'} justify={'center'}>
                <Link to={'/'}>
                    <img className={'logo'} src={logo} alt={'Logo'}/>
                </Link>
            </Flex>
            <Menu mode={'inline'} className={'menu-bar'}>
                <Menu.Item key={'1'} icon={<FolderOutlined/>}>
                    <Link to={'/'}>Repository</Link> 
                </Menu.Item>
                <Menu.SubMenu key={'2'} icon={<AppstoreAddOutlined/>} title={'Manage'}>
                    <Menu.Item key={'2-1'} icon={<TeamOutlined/>}>
                        <Link to={`/repository/${repositoryName}/member`}>Member</Link>
                    </Menu.Item>
                    <Menu.Item key={'2-2'} icon={<HistoryOutlined/>}>
                        <Link to={`/repository/${repositoryName}/commit`}>Commit</Link>
                    </Menu.Item>
                    <Menu.Item key={'2-3'} icon={<BranchesOutlined/>}>
                        <Link to={`/repository/${repositoryName}/branch`}>Branch</Link>
                    </Menu.Item>
                    <Menu.Item key={'2-4'} icon={<BugOutlined/>}>
                        <Link to={`/repository/${repositoryName}/defect`}>Defect</Link>
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key={'3'} icon={<BugOutlined/>}>
                    <Link to={'/defectIndividual'}>Defect</Link>
                </Menu.Item>
                {isRootUser && (
                    <Menu.Item key="3" icon={<UserOutlined />}>
                        <Link to="/user">User</Link>
                    </Menu.Item>
                )}
            </Menu>
        </>
    )
}
export default SiderBarDetail