import React, { useEffect, useState, useCallback } from "react";
import {
    Button,
    Col,
    Layout,
    Row,
    Select,
    message,
    List,
    Card,
    Space,
    Typography,
    Form,
    Modal
} from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SortAscendingOutlined, SortDescendingOutlined, FolderOutlined, UserOutlined, ClockCircleOutlined } from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import Search from "antd/es/input/Search";
import {useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import SideBar from "../Layout/SideBar";
import HeaderComponent from "../Layout/HeaderComponent";
import '../Assets/css/index.css';
import api from "../Ultils/Api";
import Input from "antd/es/input/Input";


const { Option } = Select;
const { Text, Title } = Typography;

function Home() {
    const [collapsed, setCollapsed] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortField, setSortField] = useState('name');
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalVisibleCreateProject,setIsModalVisibleCreateProject] = useState(false);
    const [isModalVisibleChangeServer,setIsModalVisibleChangeServer] = useState(false);
    const [hostServer,setHostServer] = useState('');
    const [userName,setUserName] = useState('');
    const [password,setPassword] = useState('');
    const [newProjectName,setNewProjectName] = useState('');
    const [listRepository, setListRepository] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { host } = useParams();

    const fetchRepositories = useCallback(async () => {
        setLoading(true);
        try {

            const response = await api.get(`git/repositories`)

            if (response.data && Array.isArray( response.data.repositories)) {
                setListRepository(response.data.repositories);
            } else {
                throw new Error("Invalid data structure received from server");
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        fetchRepositories();
    }, [fetchRepositories]);

    const handleError = (error) => {
        console.error("Error fetching repositories:", error);
        if (axios.isAxiosError(error)) {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        message.error("Your session has expired. Please log in again.");
                        navigate('/login');
                        break;
                    case 403:
                        message.error("You don't have permission to access these repositories.");
                        break;
                    default:
                        message.error("An error occurred while fetching repositories.");
                }
            } else if (error.request) {
                message.error("Network error. Please check your connection and try again.");
            } else {
                message.error("An unexpected error occurred.");
            }
        } else {
            message.error(error.message || "An unexpected error occurred");
        }
    };

    const handleRepositoryClick = (name) => {
        navigate(`/repository/${name}`);
    }

    const handleSearch = (value) => {
        setSearchTerm(value);
    }

    const handleSortChange = (value) => {
        setSortField(value);
    }

    const toggleSortOrder = () => {
        setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    }

    const sortedAndFilteredRepositories = listRepository
        .filter(repo => repo.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
            if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });

// CREATE PROJECT//
    const handleShowCreateProjectModal = () => {
        setIsModalVisibleCreateProject(true);
    }
    const handleCancelCreateProjectModal = () =>{
        setIsModalVisibleCreateProject(false);
    }
    const handleCreateProject = async () => {
        try {
            if (!newProjectName.trim()) {
                message.error('Project name cannot be empty');
                return;
            }

            // Chỉ cần chuẩn hóa khoảng trắng và thay thế bằng dấu gạch ngang
            let formattedProjectName = newProjectName.trim().replace(/\s+/g, '-');

            const response = await api.post('/git/create', {
                repo: formattedProjectName
            });

            if (response.data) {
                message.success('Project created successfully');
                setIsModalVisibleCreateProject(false);
                setNewProjectName('');
                // Refresh the repository list
                fetchRepositories();
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            message.error('Authentication failed. Please log in again.');
                            navigate('/login');
                            break;
                        case 400:
                            message.error(error.response.data.message || 'Invalid project name');
                            break;
                        default:
                            message.error(typeof error.response.data === 'string' ? error.response.data : 'Failed to create project');
                    }
                } else if (error.request) {
                    message.error('Network error. Please check your connection.');
                }
            } else {
                message.error('Failed to create project');
            }
        }
    }

// CHANGE SERVER//
    const handleShowChangeServerModal = ()=> {
        setIsModalVisibleChangeServer(true);
    }
    const handleCancelChangeServerModal = () => {
        setIsModalVisibleChangeServer(false);
    }
    const handleChangeServer = () => {

    }

    return (
        <Layout className="layout-container">
            <Sider theme="light" trigger={null} collapsible collapsed={collapsed} className="sider">
                <SideBar />
                <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    className="trigger-btn"
                />
            </Sider>
            <Layout>
                <HeaderComponent />
                <div className="home-page-content">
                    <div className="content-title">
                        <h1>Your Repositories</h1>
                    </div>
                    <Row gutter={16} className="search-sort-container">
                        <Col span={18}>
                            <Search
                                placeholder="Search repositories"
                                allowClear
                                onSearch={handleSearch}
                                style={{ width: '98%',marginLeft:'20px' }}
                            />
                        </Col>
                        <Col span={4}>
                            <Select
                                defaultValue="name"
                                style={{ width: '100%'}}
                                onChange={handleSortChange}
                            >
                                <Option value="name">Name</Option>
                                <Option value="owner">Owner</Option>
                                <Option value="lastUpdate">Last Updated</Option>
                            </Select>
                        </Col>
                        <Col span={2}>
                            <Button
                                type="text"
                                icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                                className={'sort-btn'}
                                onClick={toggleSortOrder}
                                style={{ width: '83%'}}
                            />
                        </Col>
                    </Row>
                </div>
                <div className={'home-action-buttons'}>
                    <Button onClick={handleShowChangeServerModal} style={{marginRight:'20px'}}>Change Server</Button>

                    <Button className={'btn-create-project'} onClick={handleShowCreateProjectModal}>Create Project</Button>

                    {/*CHANGE SERVER*/}
                    <Modal
                        title={'Change Server'}
                        visible={isModalVisibleChangeServer}
                        onOk={handleChangeServer}
                        onCancel={handleCancelChangeServerModal}
                        okText={'Change'}
                        cancelText={'Cancel'}
                        okButtonProps={{className: 'custom-ok-button'}}
                    >
                        <Form layout={'vertical'}>
                            <Form.Item
                                label={'Host'}
                                name={'hostServer'}
                                rules={[{required: true, message: "Please input the host of the server"}]}
                                validateTrigger={'onBlur'}
                            >
                                <Input
                                    type={'text'}
                                    placeholder={'Host'}
                                    value={hostServer}
                                    onChange={(e) => setHostServer(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item
                                label={'Username'}
                                name={'username'}
                                rules={[{required: true, message: "Please input username"}]}
                                validateTrigger={'onBlur'}
                            >
                                <Input
                                    type={'text'}
                                    placeholder={'Username'}
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item
                                label={'Password'}
                                name={'password'}
                                rules={[
                                    { required: true, message: 'Please input your password' },
                                    { min: 6, message: 'Password is too short' },
                                    { max: 30, message: 'Password is too long' },
                                ]}
                                validateTrigger={'onBlur'}
                            >
                                <Input.Password
                                    placeholder={'Password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>

                    {/*CREATE PROJECT*/}
                    <Modal
                        title={'Create Project'}
                        visible={isModalVisibleCreateProject}
                        onOk={handleCreateProject}
                        onCancel={handleCancelCreateProjectModal}
                        okText={'Create'}
                        cancelText={'Cancel'}
                        okButtonProps={{className: 'custom-ok-button'}}
                    >
                        <Form layout={'vertical'}>
                            <Form.Item
                                label={'Project Name'}
                                name={'projectName'}
                                rules={[{required: true, message: "Please input the name of the project"}]}
                                validateTrigger={'onBlur'}
                            >
                                <Input
                                    type={'text'}
                                    placeholder={'Project Name'}
                                    value={newProjectName}
                                    onChange={(e) => setNewProjectName(e.target.value)}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
                <div className="repository-list-container" style={{ padding: '24px' }}>
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 2,
                            lg: 3,
                            xl: 4,
                            xxl: 4,
                        }}
                        dataSource={sortedAndFilteredRepositories}
                        loading={loading}
                        renderItem={(repo) => (
                            <List.Item>
                                <Card
                                    hoverable
                                    onClick={() => handleRepositoryClick(repo.name)}
                                    cover={
                                        <div style={{ height: 120, display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#f0f2f5' }}>
                                            <FolderOutlined style={{ fontSize: 48, color:'orangered' }} />
                                        </div>
                                    }
                                >
                                    <Card.Meta
                                        title={<Title level={4}>{repo.name}</Title>}
                                        description={
                                            <Space direction="vertical" size="small">
                                                <Space>
                                                    <UserOutlined />
                                                    <Text type="secondary">{repo.owner}</Text>
                                                </Space>
                                                <Space>
                                                    <ClockCircleOutlined />
                                                    <Text type="secondary">{repo.lastUpdate}</Text>
                                                </Space>
                                            </Space>
                                        }
                                    />
                                </Card>
                            </List.Item>
                        )}
                    />
                </div>
            </Layout>
        </Layout>

    );
}

export default Home;