import React from "react";
import LayoutComponentDetail from "../Layout/LayoutComponentDetail";
import {UserOutlined} from "@ant-design/icons";
function CommitCode() {
    return (
        <>
            <LayoutComponentDetail>
                <div className={'content-title'}>
                    <h1>Commit message</h1>
                </div>
                <div className={'commit-code-information'}>
                    <h2><UserOutlined/> Actor</h2>
                    <h2>Branches user</h2>
                </div>
                <div className={'commit-code-content'}>
                    <b>Code is here</b>
                </div>
            </LayoutComponentDetail>
        </>
    )
}
export default CommitCode;