import React, {useState} from "react";
import {Button, Layout} from "antd";
import Sider from "antd/es/layout/Sider";
import SiderBarDetail from "./SiderBarDetail";
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import HeaderComponent from "./HeaderComponent";
function LayoutComponentDetail({children}) {
    const [collabsed,setCollabsed] = useState(false);
    return(
        <>
            <Layout className={'layout-container'}>
                <Sider theme={'light'} trigger={null} collapsible={collabsed} className={'sider'}>
                    <SiderBarDetail/>
                    <Button type={'text'}
                            icon={collabsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                            onClick={()=> setCollabsed(!collabsed)}
                            className={'trigger-btn'}
                    />
                </Sider>
                <Layout>
                    <HeaderComponent/>
                    <div className={'content'}>
                        {children}
                    </div>
                </Layout>
            </Layout>
        </>
    )
}
export default LayoutComponentDetail